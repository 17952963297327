// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

// App
import CreateAccountGrantForm, {
  CreateAccountGrantFormType,
} from '@shared/modules/AccountGrant/forms/CreateAccountGrantForm';

const useCreateAccountGrantMutation = ({
  createAccountGrantForm,
  onSuccess,
  onError,
}: {
  createAccountGrantForm: CreateAccountGrantFormType;
  onSuccess: () => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm<CreateAccountGrantFormType>({
    initialValues: createAccountGrantForm,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useCreateAccountGrantMutation.mutation,
    variables: {
      accountGrantForm: CreateAccountGrantForm.toMutation(form.values),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useCreateAccountGrantMutation.mutation = gql`
  mutation CreateAccountGrantMutation(
    $accountGrantForm: AccountGrantForm!,
  ) {
    response: createAccountGrant(
      accountGrantForm: $accountGrantForm,
    ) {
      ${gql.errors}
      accountGrant {
        id
      }
    }
  }
`;

export default useCreateAccountGrantMutation;
