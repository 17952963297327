// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {Storage} from '@supermove/sdk';

// App
import UpdateMultibranchAdminViewingOrganizationForm from '@shared/modules/User/forms/UpdateMultibranchAdminViewingOrganizationForm';

const useUpdateMultibranchAdminViewingOrganizationMutation = ({
  updateMultibranchAdminViewingOrganizationForm,
  onSuccess,
  onError,
}: {
  updateMultibranchAdminViewingOrganizationForm: any;
  onSuccess: () => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      updateMultibranchAdminViewingOrganizationForm:
        UpdateMultibranchAdminViewingOrganizationForm.toForm(
          updateMultibranchAdminViewingOrganizationForm,
        ),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateMultibranchAdminViewingOrganizationMutation.mutation,
    variables: {
      updateMultibranchAdminViewingOrganizationForm:
        UpdateMultibranchAdminViewingOrganizationForm.toMutation(
          form.values.updateMultibranchAdminViewingOrganizationForm,
        ),
    },
    onSuccess: async (response) => {
      const token = response?.token;
      if (token) {
        await Storage.setItem('token', token);
        window.location.href = '/';

        onSuccess();
      }
    },
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateMultibranchAdminViewingOrganizationMutation.mutation = gql`
  mutation updateMultibranchAdminViewingOrganization($updateMultibranchAdminViewingOrganizationForm: UpdateMultibranchAdminViewingOrganizationForm!) {
    response: updateMultibranchAdminViewingOrganization(updateMultibranchAdminViewingOrganizationForm: $updateMultibranchAdminViewingOrganizationForm) {
      ${gql.errors}
      token
    }
  }
`;

export default useUpdateMultibranchAdminViewingOrganizationMutation;
