export enum ChannelType {
  // TODO: make this OFFICE_USER?
  USER = 'USER',
  // Create a special lane just for call-related user events, so the sales app doesn't have to handle
  // office things it doesn't care about.
  SALES_USER = 'SALES_USER',
  // This is currently unused, but can be used to send events to all users in an organization
  ORGANIZATION = 'ORGANIZATION',
  // unused
  THREAD = 'THREAD',
  // unused
  PROJECT = 'PROJECT',
  // used to send keyword events to the live call page
  CALL = 'CALL',
}

export type SSEEventTypes =
  // Updates the notif count in the office app header when a new notification is received
  | 'NOTIFICATION_COUNT_UPDATED'
  // Updates the sidebar in the office app when new counts are received
  | 'MOVE_REQUEST_COUNT_UPDATED'
  | 'DUE_TASK_COUNT_UPDATED'
  // updates the live call page when a new keyword is found in a call
  | 'CALL_KEYWORD_RECOGNIZED'
  // Call status / lifecycle events go in the SALES_USER channel so that we can notify users
  // of events that happen during their calls, independent of whether they are subscribed to the call channel.
  // This is because these events can happen outside the context of a call, e.g. when a call is initiated
  | 'CALL_INITIATED_FOR_USER'
  | 'CALL_COMPLETED_FOR_USER'
  | 'CALL_ANSWERED_FOR_USER'
  | 'CHAT_COMPLETION_STATUS_UPDATED'
  | 'LEAD_EXPORT_STATUS_UPDATED'
  | 'BATCH_TRANSCRIPTION_COMPLETED'
  // Call-level
  | 'CALL_COMPLETED'
  // Conversations
  | 'COMMUNICATION_NEW';

export const SSEEventType: {[key: string]: SSEEventTypes} = {
  // Office Header Events
  NOTIFICATION_COUNT_UPDATED: 'NOTIFICATION_COUNT_UPDATED',
  // Office Sidebar Events
  MOVE_REQUEST_COUNT_UPDATED: 'MOVE_REQUEST_COUNT_UPDATED',
  DUE_TASK_COUNT_UPDATED: 'DUE_TASK_COUNT_UPDATED',

  // Sales App Live Call Page Events
  CALL_KEYWORD_RECOGNIZED: 'CALL_KEYWORD_RECOGNIZED',

  // Sales App Call Lifecycle Events (all pages)
  CALL_INITIATED_FOR_USER: 'CALL_INITIATED_FOR_USER',
  CALL_COMPLETED_FOR_USER: 'CALL_COMPLETED_FOR_USER',
  CALL_ANSWERED_FOR_USER: 'CALL_ANSWERED_FOR_USER',
  CHAT_COMPLETION_STATUS_UPDATED: 'CHAT_COMPLETION_STATUS_UPDATED',
  LEAD_EXPORT_STATUS_UPDATED: 'LEAD_EXPORT_STATUS_UPDATED',
  BATCH_TRANSCRIPTION_COMPLETED: 'BATCH_TRANSCRIPTION_COMPLETED',

  // Sales App Call-Level Events
  CALL_COMPLETED: 'CALL_COMPLETED',

  // Conversations
  COMMUNICATION_NEW: 'COMMUNICATION_NEW',
};

export type ActiveChannelSubscriptions<ResType> = Record<
  ChannelType,
  Record<string, Record<string, ChannelSubscription<ResType>>>
>;

export interface SubscriptionMetadata {
  connectionId: string;
  connectingUserId: string;
  organizationId: string;
  channelId: string;
  channelType: ChannelType;
  connectedAt: string;
  eventCount: number;
  ipAddress?: string;
  userAgent?: string;
}

export interface SSEEvent {
  channelType: ChannelType;
  channelId: string;
  eventType: SSEEventTypes;
  data: any;
  eventId?: string;
}

export interface QueryParams {
  userId?: string;
  channels?: string;
  organizationId?: string;
}

export interface SSEChannelSubscriptionRequest {
  channelType: ChannelType;
  channelId: string;
}

export type SubscriptionsInfo = {
  [key in ChannelType]?: {[key: string]: {[key: string]: SubscriptionMetadata}};
};

export interface ChannelSubscription<ResType> {
  res: ResType;
  metadata: SubscriptionMetadata;
}
