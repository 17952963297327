// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Job, ProjectModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import UpdateJobTypeForJobForm from '@shared/modules/Project/forms/UpdateJobTypeForJobForm';

const edit = withFragment(
  (project: ProjectModel) => {
    const {isEnabledResetBillingOnUpdateProjectTypesByDefault} = project.organization.features;
    return {
      projectId: project.id,
      projectTypeId: project.projectType.id,
      isBillingReset: isEnabledResetBillingOnUpdateProjectTypesByDefault,
      isBillingValuesReset: false,
      updateJobTypeForJobForms: Job.sortJobsBySequence(project.activeJobs).map((job) =>
        UpdateJobTypeForJobForm.edit(job, {isEnabledResetBillingOnUpdateProjectTypesByDefault}),
      ),
      // Private
      billingLibraryId: _.toString(project.projectType.billingLibraryId),
      projectTypeName: project.projectType.name,
    };
  },
  gql`
    ${Job.sortJobsBySequence.fragment}
    ${UpdateJobTypeForJobForm.edit.fragment}
    fragment UpdateProjectAndJobTypesForProjectForm_edit on Project {
      id
      projectType {
        id
        billingLibraryId
        name
      }
      activeJobs {
        id
        ...Job_sortJobsBySequence
        ...UpdateJobTypeForJobForm_edit
      }
      organization {
        id
        features {
          isEnabledResetBillingOnUpdateProjectTypesByDefault: isEnabled(
            feature: "RESET_BILLING_ON_UPDATE_PROJECT_TYPES_BY_DEFAULT"
          )
        }
      }
    }
  `,
);

const toForm = ({
  projectId,
  projectTypeId,
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms,

  // Private
  billingLibraryId,

  projectTypeName,
}: any) => ({
  projectId,
  projectTypeId: _.toString(projectTypeId),
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms: updateJobTypeForJobForms.map((updateJobTypeForJobForm: any) =>
    UpdateJobTypeForJobForm.toForm(updateJobTypeForJobForm),
  ),

  // Private
  billingLibraryId: _.toString(billingLibraryId),
  projectTypeName,
});

const toMutation = ({
  projectId,
  projectTypeId,
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms,
}: any) => ({
  projectId,
  projectTypeId,
  isBillingReset,
  isBillingValuesReset,
  updateJobTypeForJobForms: updateJobTypeForJobForms.map((updateJobTypeForJobForm: any) =>
    UpdateJobTypeForJobForm.toMutation(updateJobTypeForJobForm),
  ),
});

const UpdateProjectAndJobTypesForProjectForm = {
  edit,
  toForm,
  toMutation,
};

export default UpdateProjectAndJobTypesForProjectForm;
