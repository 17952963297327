// Supermove
import {gql} from '@supermove/graphql';
import {OrganizationModel, PaymentMethodModel} from '@supermove/models';
import {Percent, withFragment} from '@supermove/utils';

// App
import {PaymentMethodKindType} from '@shared/modules/PaymentMethod/enums/PaymentMethodKind';
import ProjectTypePaymentMethodForm, {
  ProjectTypePaymentMethodFormType,
} from '@shared/modules/PaymentMethod/forms/ProjectTypePaymentMethodForm';

export interface PaymentMethodFormType {
  organizationId: string;
  paymentMethodId: string | undefined;
  name: string;
  kind: PaymentMethodKindType | '';
  isEnabledForOffice: boolean;
  isEnabledForNewProjectTypes: boolean;
  feePercentage?: number;
  projectTypePaymentMethodForms: ProjectTypePaymentMethodFormType[];
  externalIntegrationPaymentMethodId: string | undefined;

  // Private
  sectionLabel: string;
  isDiscount: boolean;
}

const _new = withFragment(
  ({organization}: {organization: OrganizationModel}): PaymentMethodFormType => ({
    organizationId: organization.id as string,
    paymentMethodId: undefined,
    name: '',
    kind: '',
    isEnabledForOffice: true,
    isEnabledForNewProjectTypes: true,
    feePercentage: undefined,
    projectTypePaymentMethodForms: organization.moveProjectTypes.map((projectType) =>
      ProjectTypePaymentMethodForm.new({projectTypeId: projectType.id}),
    ),
    externalIntegrationPaymentMethodId: undefined,

    // Private
    sectionLabel: '',
    isDiscount: false,
  }),
  gql`
    fragment PaymentMethodForm_new on Organization {
      id
      moveProjectTypes: projectTypesForCategory(category: "MOVE") {
        id
      }
    }
  `,
);

const edit = withFragment(
  (
    paymentMethod: PaymentMethodModel,
    {sectionLabel = ''}: {sectionLabel?: string},
  ): PaymentMethodFormType => ({
    organizationId: paymentMethod.organizationId,
    paymentMethodId: paymentMethod.id,
    name: paymentMethod.name,
    kind: paymentMethod.kind,
    isEnabledForOffice: paymentMethod.isEnabledForOffice,
    isEnabledForNewProjectTypes: paymentMethod.isEnabledForNewProjectTypes,
    feePercentage: paymentMethod.feePercentage,
    projectTypePaymentMethodForms: paymentMethod.projectTypePaymentMethods.map(
      (projectTypePaymentMethod) => ProjectTypePaymentMethodForm.edit(projectTypePaymentMethod, {}),
    ),
    externalIntegrationPaymentMethodId: paymentMethod.externalIntegrationPaymentMethod?.id,

    // Private
    sectionLabel,
    isDiscount: paymentMethod.feePercentage ? paymentMethod.feePercentage < 0 : false,
  }),
  gql`
    ${ProjectTypePaymentMethodForm.edit.fragment}
    fragment PaymentMethodForm_edit on PaymentMethod {
      id
      organizationId
      name
      kind
      isEnabledForOffice
      isEnabledForNewProjectTypes
      feePercentage
      projectTypePaymentMethods {
        id
        ...ProjectTypePaymentMethodForm_edit
      }
      externalIntegrationPaymentMethod {
        id
      }
    }
  `,
);

export interface PaymentMethodFormToFormType {
  organizationId: string;
  paymentMethodId: string | undefined;
  name: string;
  kind: PaymentMethodKindType | '';
  isEnabledForOffice: boolean;
  isEnabledForNewProjectTypes: boolean;
  feePercentage: string;
  projectTypePaymentMethodForms: ProjectTypePaymentMethodFormType[];
  externalIntegrationPaymentMethodId: string | undefined;

  // Private
  sectionLabel: string;
  isDiscount: boolean;
}

const toForm = ({
  organizationId,
  paymentMethodId,
  name,
  kind,
  isEnabledForOffice,
  isEnabledForNewProjectTypes,
  feePercentage,
  projectTypePaymentMethodForms,
  externalIntegrationPaymentMethodId,

  // Private
  sectionLabel,
  isDiscount,
}: PaymentMethodFormType): PaymentMethodFormToFormType => {
  return {
    organizationId,
    paymentMethodId,
    name,
    kind,
    isEnabledForOffice,
    isEnabledForNewProjectTypes,
    // The fee should display as positive in the input, even if it's a discount.
    feePercentage: feePercentage ? Percent.toForm(Math.abs(feePercentage)) : '',
    projectTypePaymentMethodForms: projectTypePaymentMethodForms.map(
      (projectTypePaymentMethodForm: ProjectTypePaymentMethodFormType) =>
        ProjectTypePaymentMethodForm.toForm(projectTypePaymentMethodForm),
    ),
    externalIntegrationPaymentMethodId,

    // Private
    sectionLabel,
    isDiscount,
  };
};

const getFeePercentageToMutation = ({
  feePercentage,
  isDiscount,
}: {
  feePercentage: string;
  isDiscount: boolean;
}) => {
  const amount = Percent.toMutation(feePercentage, true);
  if (!amount) {
    return undefined;
  }
  // Fees should be stored as positive numbers, discounts should be stored as negative numbers.
  const positiveAmount = Math.abs(amount);
  return isDiscount ? -1 * positiveAmount : positiveAmount;
};

const toMutation = ({
  organizationId,
  paymentMethodId,
  name,
  kind,
  isEnabledForOffice,
  isEnabledForNewProjectTypes,
  feePercentage,
  projectTypePaymentMethodForms,
  externalIntegrationPaymentMethodId,

  // Private
  isDiscount,
}: PaymentMethodFormToFormType) => {
  return {
    organizationId,
    paymentMethodId,
    name,
    kind,
    isEnabledForOffice,
    isEnabledForNewProjectTypes,
    feePercentage: getFeePercentageToMutation({feePercentage, isDiscount}),
    projectTypePaymentMethodForms: projectTypePaymentMethodForms.map(
      (projectTypePaymentMethodForm: ProjectTypePaymentMethodFormType) =>
        ProjectTypePaymentMethodForm.toMutation(projectTypePaymentMethodForm),
    ),
    externalIntegrationPaymentMethodId,
  };
};

const PaymentMethodForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default PaymentMethodForm;
