// Libraries
import React from 'react';

// App
import {ScrollView, Space, Styled} from '@supermove/components';
import {
  useResponsive,
  useTabs,
  TabsType,
  useScrollView,
  ScrollViewType,
  useEffect,
  useState,
} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Pane, {PaneType} from '@shared/design/components/Layout/components/Pane';
import PaneGroupHeader, {
  PaneGroupHeaderType,
} from '@shared/design/components/Layout/components/PaneGroupHeader';
import Line from '@shared/design/components/Line';
import FullWidthTabs from '@shared/design/components/Tabs/FullWidthTabs';

const FixedPaneGroupContainer = Styled.View`
  flex: 1;
`;

const AllPanesContainer = Styled.View`
  flex: 1;
  flex-direction: row;
  background-color: ${colors.gray.background};
`;

const StickyTabsContainer = Styled.View`
  position: sticky;
  top: 0;
  z-index: 9999;
  background-color: ${colors.white};
`;

const PaneMinHeightContainer = Styled.View<{minHeight: number}>`
  min-height: ${({minHeight}) => minHeight}px;
`;

const PaneGroupContainer = ({
  scrollView,
  setCurrentPosition,
  isMobileScrollablePage,
  setScrollViewHeight,
  children,
}: {
  scrollView: ScrollViewType;
  setCurrentPosition: (y: number) => void;
  isMobileScrollablePage?: boolean;
  setScrollViewHeight: (y: number) => void;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  return (
    <React.Fragment>
      {responsive.desktop || !isMobileScrollablePage ? (
        <FixedPaneGroupContainer>{children}</FixedPaneGroupContainer>
      ) : (
        <ScrollView
          ref={scrollView.ref}
          onScroll={({nativeEvent}) => {
            setCurrentPosition(nativeEvent.contentOffset.y);
          }}
          onLayout={({nativeEvent}) => {
            setScrollViewHeight(nativeEvent.layout.height); // Capture the height
          }}
          scrollEventThrottle={Infinity} // Update position only at end of scroll
        >
          {children}
        </ScrollView>
      )}
    </React.Fragment>
  );
};

const AllPanes = ({isGrid, paneDefinitions}: {isGrid?: boolean; paneDefinitions: PaneType[]}) => {
  return (
    <AllPanesContainer>
      {isGrid && <Space width={16} />}
      {paneDefinitions.map((paneDefinition, index) => {
        const isRailed = !!paneDefinition.railOptions;
        return (
          <React.Fragment key={paneDefinition.key}>
            {index > 0 && (!isGrid || isRailed) && <Line isVertical />}
            <Pane {...paneDefinition} />
          </React.Fragment>
        );
      })}
    </AllPanesContainer>
  );
};

const TabPane = ({
  paneDefinitions,
  tabs,
  minHeight,
  isMobileScrollablePage,
}: {
  paneDefinitions: PaneType[];
  tabs: TabsType;
  minHeight: number;
  isMobileScrollablePage?: boolean;
}) => {
  return (
    <React.Fragment>
      {paneDefinitions.map((paneDefinition, index) => {
        return (
          <Pane
            {...paneDefinition}
            {...(isMobileScrollablePage ? {isScrollable: false} : {})}
            style={[
              {
                display: index === tabs.selectedIndex ? 'flex' : 'none',
                // The min height container ensures that the sticky header is
                // able to always stick to the top of the screen
                minHeight,
              },
              paneDefinition.style,
            ]}
          />
        );
      })}
    </React.Fragment>
  );
};

const MobileTabs = ({
  paneDefinitions,
  tabs,
  scrollView,
  stickyTabsPosition,
  currentPosition,
  isMobileScrollablePage,
}: {
  paneDefinitions: PaneType[];
  tabs: TabsType;
  scrollView: ScrollViewType;
  stickyTabsPosition: number;
  currentPosition: number;
  isMobileScrollablePage?: boolean;
}) => {
  return (
    <FullWidthTabs
      activeTabIndex={tabs.selectedIndex}
      tabs={paneDefinitions.map((paneDefinition) => ({
        key: paneDefinition.key,
        label: paneDefinition.tabLabel || paneDefinition.titleText || '',
      }))}
      handlePressTab={({index}) => {
        if (tabs.selectedIndex === index && isMobileScrollablePage) {
          scrollView.handleScrollTo({
            y: stickyTabsPosition,
            animated: currentPosition < stickyTabsPosition,
          });
        } else {
          tabs.setSelectedIndex(index);
        }
      }}
    />
  );
};

export interface PaneGroupType<T> extends PaneGroupHeaderType<T> {
  isGrid?: boolean;
  isMobileScrollablePage?: boolean;
  showMobilePaneTabs?: boolean;
  mobilePaneTabs?: TabsType;
  paneDefinitions: PaneType[];
}

const PaneGroup = <T,>({
  isGrid = false,
  isMobileScrollablePage = false,
  showMobilePaneTabs = false,
  mobilePaneTabs,
  paneDefinitions = [],
  ...paneGroupHeaderProps
}: PaneGroupType<T>) => {
  const responsive = useResponsive();
  const defaultTabs = useTabs({initialIndex: 0});
  const tabs = mobilePaneTabs || defaultTabs;
  const scrollView = useScrollView();
  const [currentPosition, setCurrentPosition] = useState(0);
  const [stickyTabsPosition, setStickyTabsPosition] = useState(0);
  const [stickyTabsHeight, setStickyTabsHeight] = useState(0);
  const [scrollViewHeight, setScrollViewHeight] = useState(0);

  useEffect(() => {
    if (!responsive.desktop && showMobilePaneTabs && isMobileScrollablePage) {
      scrollView.handleScrollTo({
        y: stickyTabsPosition,
        animated: currentPosition < stickyTabsPosition,
      });
    }
  }, [tabs.selectedIndex]);

  // On desktop, full height panes are rendered outside of the PaneGroup in
  // MultiPaneLayout. On mobile they are handled here so that they can be
  // handled together with the tabs.
  const tabSelectedPaneDefinition = paneDefinitions[tabs.selectedIndex];
  const isHeaderHidden = !responsive.desktop && tabSelectedPaneDefinition.isFullHeight;

  return (
    <PaneGroupContainer
      scrollView={scrollView}
      setCurrentPosition={setCurrentPosition}
      isMobileScrollablePage={isMobileScrollablePage}
      setScrollViewHeight={setScrollViewHeight}
    >
      {!isHeaderHidden && <PaneGroupHeader {...paneGroupHeaderProps} />}
      {showMobilePaneTabs && !responsive.desktop ? (
        <React.Fragment>
          <Line weight={4} />
          <StickyTabsContainer
            onLayout={({nativeEvent}) => {
              setStickyTabsPosition(nativeEvent.layout.y);
              setStickyTabsHeight(nativeEvent.layout.height);
            }}
          >
            <Space height={16} />
            <MobileTabs
              paneDefinitions={paneDefinitions}
              tabs={tabs}
              scrollView={scrollView}
              stickyTabsPosition={stickyTabsPosition}
              currentPosition={currentPosition}
              isMobileScrollablePage={isMobileScrollablePage}
            />
            <Line />
          </StickyTabsContainer>
        </React.Fragment>
      ) : (
        <Line />
      )}
      {responsive.desktop ? (
        <AllPanes isGrid={isGrid} paneDefinitions={paneDefinitions} />
      ) : (
        <TabPane
          paneDefinitions={paneDefinitions}
          tabs={tabs}
          isMobileScrollablePage={isMobileScrollablePage}
          minHeight={showMobilePaneTabs ? scrollViewHeight - stickyTabsHeight : 0}
        />
      )}
    </PaneGroupContainer>
  );
};

export default PaneGroup;
