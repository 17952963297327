// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';

// App
import OrganizationReplyToForm, {
  OrganizationReplyFormFieldsType,
} from '@shared/modules/Organization/forms/OrganizationReplyToForm';

const useUpdateOrganizationReplyToMutation = ({
  organizationReplyToForm,
  onSuccess,
  onError,
}: {
  organizationReplyToForm: OrganizationReplyFormFieldsType;
  onSuccess: ({organization}: {organization: OrganizationModel}) => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm({
    initialValues: {
      organizationReplyToForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateOrganizationReplyToMutation.mutation,
    variables: {
      organizationReplyToForm: OrganizationReplyToForm.toMutation(
        form.values.organizationReplyToForm,
      ),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateOrganizationReplyToMutation.mutation = gql`
  mutation useUpdateOrganizationReplyToMutation($organizationReplyToForm: OrganizationReplyToForm!) {
    response: updateOrganizationReplyTo(organizationReplyToForm: $organizationReplyToForm) {
      ${gql.errors}
      organization {
        id
      }
    }
  }
`;

export default useUpdateOrganizationReplyToMutation;
