// Libraries
import React from 'react';

// Components
import LayoutBuilder from '@shared/design/components/Layout/components/LayoutBuilder';
import {PaneType} from '@shared/design/components/Layout/components/Pane';
import PaneGroup, {PaneGroupType} from '@shared/design/components/Layout/components/PaneGroup';

interface SinglePaneType extends Omit<PaneType, 'key'> {}
export interface SinglePaneLayoutProps<T>
  extends Omit<PaneGroupType<T>, 'isGrid' | 'paneDefinitions'> {
  paneDefinition: SinglePaneType;
}

const SinglePaneLayout = <T,>({paneDefinition, ...paneGroupProps}: SinglePaneLayoutProps<T>) => {
  return (
    <LayoutBuilder.LayoutContainer>
      <PaneGroup {...paneGroupProps} paneDefinitions={[{key: 'SINGLE_PANE', ...paneDefinition}]} />
    </LayoutBuilder.LayoutContainer>
  );
};

export default SinglePaneLayout;
