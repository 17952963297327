export interface TagAssignmentForm {
  tagAssignmentId: number | null;
  tagIds: number[];
  jobId: number | null;
  projectId: number | null;
  callId: number | null;
  isDeleted: boolean;
}

export interface TagAssignmentFormAsMutation {
  tagAssignmentId: number | null;
  tagIds: number[];
  jobId: number | null;
  projectId: number | null;
  callId: number | null;
  isDeleted: boolean;
}

const _new = ({
  projectId,
  jobId,
  callId,
  tagIds,
}: {
  projectId: number | null;
  jobId: number | null;
  callId: number | null;
  tagIds: number[];
}): TagAssignmentForm => ({
  tagAssignmentId: null,
  tagIds: tagIds || [],
  jobId,
  projectId,
  callId,
  isDeleted: false,
});

const _delete = ({
  tagAssignmentId,
  jobId,
  projectId,
  callId = null,
}: {
  tagAssignmentId: number;
  jobId?: number | null;
  projectId?: number | null;
  callId?: number | null;
}) => ({
  ..._new(
    {} as {projectId: number | null; jobId: number | null; callId: number | null; tagIds: number[]},
  ),
  tagAssignmentId,
  jobId,
  projectId,
  callId,
});

const toForm = (tagAssignmentForm: TagAssignmentForm): TagAssignmentForm => ({
  tagAssignmentId: tagAssignmentForm.tagAssignmentId,
  tagIds: tagAssignmentForm.tagIds,
  jobId: tagAssignmentForm.jobId,
  projectId: tagAssignmentForm.projectId,
  callId: tagAssignmentForm.callId,
  isDeleted: tagAssignmentForm.isDeleted,
});

const toMutation = (tagAssignmentForm: TagAssignmentForm): TagAssignmentFormAsMutation => ({
  tagAssignmentId: tagAssignmentForm.tagAssignmentId,
  tagIds: tagAssignmentForm.tagIds,
  jobId: tagAssignmentForm.jobId,
  projectId: tagAssignmentForm.projectId,
  callId: tagAssignmentForm.callId,
  isDeleted: tagAssignmentForm.isDeleted,
});

const TagAssignmentForm = {
  new: _new,
  delete: _delete,
  toForm,
  toMutation,
};

export default TagAssignmentForm;
