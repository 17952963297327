// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {CurrencyInput, DropdownInput, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import FieldValue from '@shared/design/components/Field/FieldValue';
import JobValuationCoverageOptionsTable from '@shared/modules/Job/components/JobValuationCoverageOptionsTable';

const Section = Styled.View`
  z-index: ${(props) => 100 - (props as any).sectionIndex};
`;

const SectionSpace = Styled.View`
  margin-top: 20px;
`;

const SectionTitle = Styled.Text`
  ${Typography.Body3}
`;

// Valuation Coverage Input
const TextInput = Styled.TextInput`
  ${Typography.Body3}
  margin-top: 3px;
`;

const ValidationError = Styled.Text`
  ${Typography.Body3}
  margin-top: 5px;
  color: ${colors.red.warning};
`;

const getValuationCoverageDropdownOptions = ({valuationCoverageOptions}: any) => {
  // @ts-expect-error TS(7006): Parameter 'valuationCoverageOption' implicitly has... Remove this comment to see the full error message
  return valuationCoverageOptions.map((valuationCoverageOption, index) => ({
    label: valuationCoverageOption.displayName,
    value: index,
  }));
};

const getAmountValueOptions = ({values, valuationCoverageMinimum}: any) => {
  const filteredValues = values.filter((value: any) => value >= valuationCoverageMinimum);
  // @ts-expect-error TS(7006): Parameter 'value' implicitly has an 'any' type.
  return filteredValues.map((value, index) => ({
    label: Currency.display(value),
    value: Currency.toForm(value),
  }));
};

const getAdditionalCostFromValuationCoverageOption = ({
  amountValue,
  values,
  valuationCoverageOption,
}: any) => {
  const index = _.findIndex(values, (value) => value === amountValue);
  return _.get(valuationCoverageOption, `values.${index}`);
};

const getAdditionalCost = ({form, field, values, valuationCoverageOption}: any) => {
  if (!valuationCoverageOption) {
    return 0;
  }
  const amountValue = Currency.toMutation(_.get(form.values, `${field}.amountValue`));
  const {transportationRateAmount, transportationRatePer} = valuationCoverageOption;

  if (values.length > 0) {
    return getAdditionalCostFromValuationCoverageOption({
      amountValue,
      values,
      valuationCoverageOption,
    });
  }

  if (!transportationRateAmount || !transportationRatePer) {
    return 0;
  }

  return (amountValue * transportationRateAmount) / transportationRatePer;
};

const getAmountMinimum = ({valuationCoverageMinimum, valuationCoverageOption}: any) => {
  const {amountMinimum} = valuationCoverageOption;
  const maxMinimum = Math.max(amountMinimum, valuationCoverageMinimum);
  if (maxMinimum && maxMinimum > 0) {
    return Currency.toForm(maxMinimum);
  }
  return null;
};

const JobValuationCoverageFields = ({form, field, job}: any) => {
  const {valuationCoverageMinimum} = job.project;
  const {valuationCoverageOptions, values} = job.jobType.valuationCoverageConfig;
  const selectedValuationCoverageOption = _.get(
    valuationCoverageOptions,
    _.get(form.values, `${field}.selectedIndex`),
  );
  const additionalCost = getAdditionalCost({
    form,
    field,
    values,
    valuationCoverageOption: selectedValuationCoverageOption,
  });

  return (
    <React.Fragment>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={0}>
        <SectionTitle>Minimum Shipment Value ($)</SectionTitle>
        <FieldInput.Memoized
          {...form}
          component={CurrencyInput}
          name={`${field}.amountMinimum`}
          input={{
            disabled: true,
            component: TextInput,
            placeholder: 'Enter amount',
            setFieldValue: (name: any, value: any) => {
              form.setFieldValue(name, value);
              const amountMinimum = Currency.toMutation(
                _.get(form.values, `${field}.amountMinimum`),
              );
              const amountValue = Currency.toMutation(_.get(form.values, `${field}.amountValue`));
              if (amountMinimum >= amountValue) {
                form.setFieldValue(`${field}.amountValue`, value);
              }
            },
            setFieldTouched: form.setFieldTouched,
          }}
        />
      </Section>
      <SectionSpace />
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <Section sectionIndex={1}>
        <SectionTitle>Valuation Coverage Option</SectionTitle>
        <FieldInput.Memoized
          {...form}
          component={DropdownInput}
          name={`${field}.selectedIndex`}
          input={{
            options: getValuationCoverageDropdownOptions({valuationCoverageOptions}),
            placeholder: 'Select one',
            setFieldValue: (name: any, value: any) => {
              const valuationCoverageOption = _.get(valuationCoverageOptions, value);
              const {deductible, category} = valuationCoverageOption;
              const deductibleInCents = deductible === null ? null : Currency.toForm(deductible);
              const amountMinimum = getAmountMinimum({
                valuationCoverageMinimum,
                valuationCoverageOption,
              });

              form.setFieldValue(name, value);
              form.setFieldValue(`${field}.category`, category);
              form.setFieldValue(`${field}.deductible`, deductibleInCents);
              form.setFieldValue(`${field}.amountMinimum`, amountMinimum);
              form.setFieldValue(`${field}.amountValue`, amountMinimum);
            },
            style: {
              width: '100%',
            },
          }}
          style={{
            width: 300,
            marginTop: 3,
          }}
        />
        <ValidationError>{_.get(form.errors, `${field}.category`)}</ValidationError>
      </Section>
      {
        <React.Fragment>
          <SectionSpace />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section sectionIndex={2}>
            <SectionTitle>Shipment Value ($)</SectionTitle>
            {values.length > 0 ? (
              <FieldInput.Memoized
                {...form}
                component={DropdownInput}
                name={`${field}.amountValue`}
                input={{
                  options: getAmountValueOptions({
                    values,
                    valuationCoverageMinimum: selectedValuationCoverageOption?.amountMinimum || 0,
                  }),
                  placeholder: 'Select option',
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                  style: {
                    width: '100%',
                  },
                  isSearchable: true,
                }}
                style={{
                  width: 300,
                  marginTop: 3,
                }}
              />
            ) : (
              <FieldInput.Memoized
                {...form}
                component={CurrencyInput}
                name={`${field}.amountValue`}
                input={{
                  component: TextInput,
                  placeholder: 'Enter amount',
                  setFieldValue: form.setFieldValue,
                  setFieldTouched: form.setFieldTouched,
                }}
                style={{
                  width: 300,
                  marginTop: 3,
                }}
              />
            )}
          </Section>
          <SectionSpace />
          {/* @ts-expect-error TS(2769): No overload matches this call. */}
          <Section sectionIndex={3}>
            <SectionTitle>Additional Cost</SectionTitle>
            <FieldValue numberOfLines={1} value={Currency.display(additionalCost)} />
          </Section>
        </React.Fragment>
      }
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobValuationCoverageFields.fragment = gql`
  ${JobValuationCoverageOptionsTable.fragment}

  fragment JobValuationCoverageFields on Job {
    id
    project {
      id
      valuationCoverageMinimum
    }
    jobType {
      id
      valuationCoverageConfig {
        values
        valuationCoverageOptions {
          category
          deductible
          displayName
          amountMinimum
          transportationRateAmount
          transportationRatePer
          values
        }
      }
    }
    ...JobValuationCoverageOptionsTable
  }
`;

export default JobValuationCoverageFields;
