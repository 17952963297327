import _ from 'lodash';
import moment from 'moment';
import pluralizeHelper from 'pluralize';

import {
  fixedArityOne,
  fixedArityTwo,
  fixedArityThree,
  variableArityatLeastOne,
  variableArityatLeastFour,
  variableArityatLeastThree,
  variableArityTwoOrThree,
  evenArity,
  oddArity,
} from '@shared/formulas/src/library/arity';
import {ensureNumber, ensureAlways, ensureString} from '@shared/formulas/src/library/types';

// Datetime helpers
const fromDate = (string: string, format: string = 'YYYY-MM-DD') => moment(string, format);
const fromTime = (string: string, format: string = 'HHmm') => moment(string, format);

const pipe =
  (...functions: ((args: any) => any)[]) =>
  (initialValue: any) => {
    return functions.reduce((currentValue, currentFunction) => {
      return currentFunction(currentValue);
    }, initialValue);
  };

export interface FunctionDefinition {
  call: (...args: any[]) => any;
  checkArity: (args: any[]) => void;
  checkTypes: (args: any[]) => void;
}

export const add = {
  checkArity: fixedArityTwo('add'),
  checkTypes: ensureAlways('add'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left + right;
  },
};

export const subtract = {
  checkArity: fixedArityTwo('subtract'),
  checkTypes: ensureNumber('subtract'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left - right;
  },
};

export const multiply = {
  checkArity: fixedArityTwo('multiply'),
  checkTypes: ensureNumber('multiply'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left * right;
  },
};

export const divide = {
  checkArity: fixedArityTwo('divide'),
  checkTypes: ensureNumber('divide'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    if (right === 0) {
      return right;
    }
    return left / right;
  },
};

export const unaryMinus = {
  checkArity: fixedArityOne('unaryMinus'),
  checkTypes: ensureNumber('unaryMinus'),
  call: (arg: any) => {
    if (arg === null) {
      return null;
    }
    return -arg;
  },
};

export const not = {
  checkArity: fixedArityOne('not'),
  checkTypes: ensureAlways('not'),
  call: (arg: any) => !arg,
};

export const and = {
  checkArity: fixedArityTwo('and'),
  checkTypes: ensureAlways('and'),
  call: (left: any, right: any) => Boolean(left && right),
};

export const or = {
  checkArity: fixedArityTwo('or'),
  checkTypes: ensureAlways('or'),
  call: (left: any, right: any) => Boolean(left || right),
};

export const equal = {
  checkArity: fixedArityTwo('equal'),
  checkTypes: ensureAlways('equal'),
  call: (left: any, right: any) => left === right,
};

export const unequal = {
  checkArity: fixedArityTwo('unequal'),
  checkTypes: ensureAlways('unequal'),
  call: (left: any, right: any) => left !== right,
};

export const greaterThan = {
  checkArity: fixedArityTwo('greaterThan'),
  checkTypes: ensureNumber('greaterThan'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left > right;
  },
};

export const greaterEq = {
  checkArity: fixedArityTwo('greaterEq'),
  checkTypes: ensureNumber('greaterEq'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left >= right;
  },
};

export const lessThan = {
  checkArity: fixedArityTwo('lessThan'),
  checkTypes: ensureNumber('lessThan'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left < right;
  },
};

export const lessEq = {
  checkArity: fixedArityTwo('lessEq'),
  checkTypes: ensureNumber('lessEq'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return left <= right;
  },
};

export const ceil = {
  checkArity: fixedArityOne('ceil'),
  checkTypes: ensureNumber('ceil'),
  call: (arg: any) => {
    if (arg === null) {
      return null;
    }
    return Math.ceil(arg);
  },
};

export const floor = {
  checkArity: fixedArityOne('floor'),
  checkTypes: ensureNumber('floor'),
  call: (arg: any) => {
    if (arg === null) {
      return null;
    }
    return Math.floor(arg);
  },
};

export const max = {
  checkArity: fixedArityTwo('max'),
  checkTypes: ensureNumber('max'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return Math.max(left, right);
  },
};

export const min = {
  checkArity: fixedArityTwo('min'),
  checkTypes: ensureNumber('min'),
  call: (left: any, right: any) => {
    if (left === null || right === null) {
      return null;
    }
    return Math.min(left, right);
  },
};

export const iff = {
  checkArity: fixedArityThree('if'),
  checkTypes: ensureAlways('if'),
  call: (condition: any, ifCondition: any, elseCondition: any) => {
    if (condition) {
      return ifCondition;
    } else {
      return elseCondition;
    }
  },
};

export const string = {
  checkArity: fixedArityOne('string'),
  checkTypes: ensureAlways('string'),
  call: (arg: any) => {
    if (arg === null) {
      return 'TBD';
    }
    return String(arg);
  },
};

export const number = {
  checkArity: fixedArityOne('number'),
  checkTypes: ensureAlways('number'),
  call: (arg: any) => {
    if (arg === null) {
      return null;
    }
    return Number(arg) || 0;
  },
};

export const round = {
  checkArity: variableArityatLeastOne('round'),
  checkTypes: ensureNumber('round'),
  call: (roundee: number, roundTo: number = 1) => {
    if (roundee === null) {
      return null;
    }
    // Logic for round_to numbers less than 1
    // Necessary to trim any small decimals due to float math https://docs.python.org/3/tutorial/floatingpoint.html
    // The idea is to only do integer math to prevent any floating small decimals
    if (roundTo < 1) {
      let multiplier = 1;
      let tempRoundTo = roundTo;
      // Scale up the temp_round_to so it does not have any decimals
      // Multiplier is used to know how many places to move the decimal over after rounding
      while (tempRoundTo % 1 !== 0) {
        multiplier *= 10;
        tempRoundTo *= 10;
      }
      return Math.round(Math.round(roundee / roundTo) * tempRoundTo) / multiplier;
    }
    return Math.round(roundee / roundTo) * roundTo;
  },
};

export const value = {
  checkArity: fixedArityOne('value'),
  checkTypes: ensureString('value'),
  call: (valueName: string) => 0,
};

export const _var = {
  checkArity: fixedArityOne('var'),
  checkTypes: ensureString('var'),
  call: (varName: string) => 0,
};

export const formatNumber = {
  checkArity: fixedArityTwo('formatNumber'),
  checkTypes: ensureNumber('formatNumber'),
  call: (impreciseNumber: number, precision: number) => {
    if (impreciseNumber === null) {
      return null;
    }
    return impreciseNumber.toFixed(precision);
  },
};

export const dollars = {
  checkArity: fixedArityOne('dollars'),
  checkTypes: ensureNumber('dollars'),
  call: (cents: number) => {
    if (cents === null) {
      return null;
    }
    return `$${formatNumber.call(cents / 100, 2)}`;
  },
};

export const _switch = {
  checkArity: pipe(
    variableArityatLeastFour('switch'),
    evenArity('switch', 'Switch requires a default value as the last argument.'),
  ),

  checkTypes: ensureAlways('switch'),
  call: (valueToSwitch: any, ...args: any[]) => {
    const optionPairs = args.slice(0, -1);
    const defaultValue = args[args.length - 1];
    const chunkedArgs = _.chunk(optionPairs, 2);
    for (const [valueToMatch, valueToReturn] of chunkedArgs) {
      if (valueToMatch === valueToSwitch) {
        return valueToReturn;
      }
    }
    return defaultValue;
  },
};

export const ifs = {
  checkArity: pipe(
    variableArityatLeastThree('ifs'),
    oddArity('ifs', 'Ifs requires a default value as the last argument.'),
  ),
  checkTypes: ensureAlways('ifs'),
  call: (...args: any[]) => {
    const optionPairs = args.slice(0, args.length - 1);
    const defaultValue = args[args.length - 1];
    const chunkedArgs = _.chunk(optionPairs, 2);
    for (const [condition, valueToReturn] of chunkedArgs) {
      if (condition) {
        return valueToReturn;
      }
    }
    return defaultValue;
  },
};

export const day = {
  checkArity: fixedArityOne('day'),
  checkTypes: ensureAlways('day'),
  call: (date: string) => {
    if (date === null || date === '') {
      return null;
    }
    return fromDate(date).date();
  },
};

export const dayOfWeek = {
  checkArity: fixedArityOne('dayOfWeek'),
  checkTypes: ensureAlways('dayOfWeek'),
  call: (date: string) => {
    if (date === null || date === '') {
      return null;
    }
    return fromDate(date).isoWeekday();
  },
};

export const month = {
  checkArity: fixedArityOne('month'),
  checkTypes: ensureAlways('month'),
  // months are 0 indexed in moment
  call: (date: string) => {
    if (date === null || date === '') {
      return null;
    }
    return fromDate(date).month() + 1;
  },
};

export const year = {
  checkArity: fixedArityOne('year'),
  checkTypes: ensureAlways('year'),
  call: (date: string) => {
    if (date === null || date === '') {
      return null;
    }
    return fromDate(date).year();
  },
};

export const isWeekday = {
  checkArity: fixedArityOne('isWeekday'),
  checkTypes: ensureAlways('isWeekday'),
  call: (date: string) => {
    if (date === null || date === '') {
      return null;
    }
    const isoWeekday = fromDate(date).isoWeekday();
    return isoWeekday >= 1 && isoWeekday <= 5;
  },
};

export const isWeekend = {
  checkArity: fixedArityOne('isWeekend'),
  checkTypes: ensureAlways('isWeekend'),
  call: (date: string) => {
    if (date === null || date === '') {
      return null;
    }
    const isoWeekday = fromDate(date).isoWeekday();
    return isoWeekday === 6 || isoWeekday === 7;
  },
};

export const minute = {
  checkArity: fixedArityOne('minute'),
  checkTypes: ensureAlways('minute'),
  call: (time: string) => {
    if (time === null || time === '') {
      return null;
    }
    return fromTime(time).minutes();
  },
};

export const hour = {
  checkArity: fixedArityOne('hour'),
  checkTypes: ensureAlways('hour'),
  call: (time: string) => {
    if (time === null || time === '') {
      return null;
    }
    return fromTime(time).hours();
  },
};

export const pluralize = {
  checkArity: variableArityTwoOrThree('pluralize'),
  checkTypes: ensureAlways('pluralize'),
  call: (inputString: string | null, count: number | null, inclusive: boolean = true) => {
    if (inputString === null || inputString === '') {
      return '';
    }
    const safeCount = count || 0;
    return pluralizeHelper(inputString, safeCount, inclusive);
  },
};

export const concat = {
  checkArity: fixedArityTwo('concat'),
  checkTypes: ensureAlways('concat'),
  call: (left: any, right: any) => {
    if (left === null && right === null) {
      return '';
    }
    if (left === null) {
      return `${right}`;
    }
    if (right === null) {
      return `${left}`;
    }
    return `${left}${right}`;
  },
};

export const roundToQuarterHour = {
  checkArity: fixedArityOne('roundToQuarterHour'),
  checkTypes: ensureNumber('roundToQuarterHour'),
  call: (input: number) => {
    if (input === null) {
      return null;
    }
    return Math.round(input * 4) / 4;
  },
};

export const roundDownToQuarterHour = {
  checkArity: fixedArityOne('roundDownToQuarterHour'),
  checkTypes: ensureNumber('roundDownToQuarterHour'),
  call: (input: number) => {
    if (input === null) {
      return null;
    }
    return Math.floor(input * 4) / 4;
  },
};

export const roundUpToQuarterHour = {
  checkArity: fixedArityOne('roundUpToQuarterHour'),
  checkTypes: ensureNumber('roundUpToQuarterHour'),
  call: (input: number) => {
    if (input === null) {
      return null;
    }
    return Math.ceil(input * 4) / 4;
  },
};

export const roundToHalfHour = {
  checkArity: fixedArityOne('roundToHalfHour'),
  checkTypes: ensureNumber('roundToHalfHour'),
  call: (input: number) => {
    if (input === null) {
      return null;
    }
    return Math.round(input * 2) / 2;
  },
};

export const roundDownToHalfHour = {
  checkArity: fixedArityOne('roundDownToHalfHour'),
  checkTypes: ensureNumber('roundDownToHalfHour'),
  call: (input: number) => {
    if (input === null) {
      return null;
    }
    return Math.floor(input * 2) / 2;
  },
};

export const roundUpToHalfHour = {
  checkArity: fixedArityOne('roundUpToHalfHour'),
  checkTypes: ensureNumber('roundUpToHalfHour'),
  call: (input: number) => {
    if (input === null) {
      return null;
    }
    return Math.ceil(input * 2) / 2;
  },
};

export const functions: {[name: string]: FunctionDefinition} = {
  add,
  subtract,
  multiply,
  divide,
  unaryMinus,
  not,
  and,
  or,
  equal,
  unequal,
  greaterThan,
  greaterEq,
  lessThan,
  lessEq,
  ceil,
  floor,
  max,
  min,
  if: iff,
  ifs,
  string,
  number,
  round,
  value,
  var: _var,
  formatNumber,
  dollars,
  switch: _switch,
  day,
  dayOfWeek,
  month,
  year,
  isWeekday,
  isWeekend,
  minute,
  hour,
  pluralize,
  concat,
  roundToQuarterHour,
  roundDownToQuarterHour,
  roundUpToQuarterHour,
  roundToHalfHour,
  roundDownToHalfHour,
  roundUpToHalfHour,
};
