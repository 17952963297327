export const makeFixedArityCheck =
  (nAry: number) => (name: string, errorMessageOverride?: string) => (args: any[]) => {
    if (args.length !== nAry) {
      throw new TypeError(errorMessageOverride || `${name} takes exactly ${nAry} argument(s)`);
    }
    return args;
  };

export const makeVariableArityCheck =
  (minArity: number, maxArity?: number) =>
  (name: string, errorMessageOverride?: string) =>
  (args: any[]) => {
    if (args.length < minArity) {
      throw new TypeError(
        errorMessageOverride || `${name} must have at least ${minArity} argument(s)`,
      );
    }
    if (maxArity && args.length > maxArity) {
      throw new TypeError(
        errorMessageOverride || `${name} can have at most ${maxArity} argument(s)`,
      );
    }
    return args;
  };

export const makeEvenArityCheck =
  () => (name: string, errorMessageOverride?: string) => (args: any[]) => {
    if (args.length % 2 !== 0) {
      throw new TypeError(errorMessageOverride || `${name} must have an even number of arguments`);
    }
    return args;
  };

export const makeOddArityCheck =
  () => (name: string, errorMessageOverride?: string) => (args: any[]) => {
    if (args.length % 2 === 0) {
      throw new TypeError(errorMessageOverride || `${name} must have an odd number of arguments`);
    }
    return args;
  };

export const fixedArityOne = makeFixedArityCheck(1);
export const fixedArityTwo = makeFixedArityCheck(2);
export const fixedArityThree = makeFixedArityCheck(3);
export const variableArityatLeastOne = makeVariableArityCheck(1);
export const variableArityatLeastThree = makeVariableArityCheck(3);
export const variableArityatLeastFour = makeVariableArityCheck(4);
export const variableArityTwoOrThree = makeVariableArityCheck(2, 3);
export const evenArity = makeEvenArityCheck();
export const oddArity = makeOddArityCheck();
