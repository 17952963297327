// App
import {Styled} from '@supermove/components';

// Components
import Text from '@shared/design/components/Text';

const LayoutContainer = Styled.View`
  flex: 1;
`;

export default {
  LayoutContainer,
  TitleText: Text.Heading2,
};
