export const parseJsonRecipients = (emails?: string): string[] => {
  if (!emails) {
    return [];
  }
  try {
    return JSON.parse(emails).map((emailInfo: {email: string}) => emailInfo.email);
  } catch (e) {
    return [];
  }
};
