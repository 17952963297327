// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {UserModel} from '@supermove/models';
import {Config} from '@supermove/sdk';
import {withFragment} from '@supermove/utils';

// App
import EmailSenderKind, {
  EmailSenderKindType,
} from '@shared/modules/Organization/enums/EmailSenderKind';
import UserRole from '@shared/modules/User/enums/UserRole';

const navigateToNylasOauthUrl = (redirectUri: string) => {
  const clientId: string = Config.getNylasClientId();
  const nylasUrl = new URL('https://api.us.nylas.com/v3/connect/auth');
  const params = new URLSearchParams({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: 'code',
    access_type: 'online',
  });

  // Redirect the user to the Nylas auth url.
  nylasUrl.search = params.toString();
  window.location.href = nylasUrl.toString();
};

export type AccountGrantInfoType = {
  hasExpiredCompanyAccount: boolean;
  hasExpiredUserAccount: boolean;
  hasActiveCompanyAccount: boolean;
  hasActiveUserAccount: boolean;
  hasEditPermissions: boolean;
  hasSendableAccountGrant: boolean;
  userAccountDaysUntilExpiration?: number;
  companyAccountDaysUntilExpiration?: number;
  expiredCompanyAccountStatusUpdatedAt?: string;
  expiredUserAccountStatusUpdatedAt?: string;
  emailSenderKind: EmailSenderKindType;
};

const accountGrantInfo = withFragment<UserModel, AccountGrantInfoType, unknown>(
  (viewer?: UserModel) => {
    if (!viewer) {
      return {
        hasExpiredCompanyAccount: false,
        hasExpiredUserAccount: false,
        hasActiveCompanyAccount: false,
        hasActiveUserAccount: false,
        hasSendableAccountGrant: false,
        userAccountDaysUntilExpiration: undefined,
        companyAccountDaysUntilExpiration: undefined,
        hasEditPermissions: false,
        emailSenderKind: EmailSenderKind.ORGANIZATION,
      };
    }
    const hasEditPermissions = _.includes(UserRole.ADMIN_ROLES_PLUS_SUPER, viewer.role);
    const organization = viewer.viewingOrganization;
    const {emailSenderKind} = organization.settings;

    // If sending through supermove, accounts are irrelevant
    if (
      organization.settings.emailSenderKind === EmailSenderKind.SUPERMOVE ||
      !organization.features.isEnabledAccountGrantExpired
    ) {
      return {
        hasExpiredCompanyAccount: false,
        hasExpiredUserAccount: false,
        hasActiveCompanyAccount: false,
        hasActiveUserAccount: false,
        hasSendableAccountGrant: false,
        userAccountDaysUntilExpiration: undefined,
        companyAccountDaysUntilExpiration: undefined,
        hasEditPermissions,
        emailSenderKind,
      };
    }

    const hasExpiredCompanyAccount = !!organization.expiredAccountGrant?.id;
    const hasActiveCompanyAccount = !!organization.activeAccountGrant?.id;
    // If sending through the organization, the user account is irrelevant
    if (organization.settings.emailSenderKind === EmailSenderKind.ORGANIZATION) {
      return {
        hasExpiredCompanyAccount,
        hasActiveCompanyAccount,
        hasExpiredUserAccount: false,
        hasActiveUserAccount: false,
        hasSendableAccountGrant: hasActiveCompanyAccount,
        userAccountDaysUntilExpiration: undefined,
        companyAccountDaysUntilExpiration: organization.activeAccountGrant?.daysUntilExpiration,
        hasEditPermissions,
        expiredCompanyAccountStatusUpdatedAt: organization.expiredAccountGrant?.statusUpdatedAt,
        emailSenderKind,
      };
    }

    const hasExpiredUserAccount = !!viewer.expiredAccountGrant?.id;
    const hasActiveUserAccount = !!viewer.activeAccountGrant?.id;
    return {
      hasExpiredCompanyAccount,
      hasActiveCompanyAccount,
      hasExpiredUserAccount,
      hasActiveUserAccount,
      hasEditPermissions,
      hasSendableAccountGrant: hasActiveCompanyAccount || hasActiveUserAccount,
      userAccountDaysUntilExpiration: viewer.activeAccountGrant?.daysUntilExpiration,
      companyAccountDaysUntilExpiration: organization.activeAccountGrant?.daysUntilExpiration,
      expiredUserAccountStatusUpdatedAt: viewer.expiredAccountGrant?.statusUpdatedAt,
      expiredCompanyAccountStatusUpdatedAt: organization.expiredAccountGrant?.statusUpdatedAt,
      emailSenderKind,
    };
  },
  gql`
    fragment AccountGrant_accountGrantInfo on User {
      id
      role
      activeAccountGrant {
        id
        daysUntilExpiration
      }
      expiredAccountGrant {
        id
        statusUpdatedAt
      }
      viewingOrganization {
        id
        features {
          isEnabledAccountGrantExpired: isEnabled(feature: "ACCOUNT_GRANT_EXPIRED")
        }
        settings {
          id
          emailSenderKind
        }
        activeAccountGrant {
          id
          daysUntilExpiration
        }
        expiredAccountGrant {
          id
          statusUpdatedAt
        }
      }
    }
  `,
);

const AccountGrant = {
  navigateToNylasOauthUrl,
  accountGrantInfo,
};

export default AccountGrant;
