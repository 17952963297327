// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';
import {Typography} from '@supermove/styles';
import {TextProps as ParentTextProps} from '@supermove/styles/types';

const PageHeadingText = Styled.Text`
  ${Typography.Responsive.PageHeading}
`;
const Heading1Text = Styled.Text`
  ${Typography.Responsive.Heading1}
`;
const Heading2Text = Styled.Text`
  ${Typography.Responsive.Heading2}
`;
const SubheadingText = Styled.Text`
  ${Typography.Responsive.Subheading}
`;
const LabelText = Styled.Text`
  ${Typography.Responsive.Label}
`;
const BodyText = Styled.Text`
  ${Typography.Responsive.Body}
`;
const LinkText = Styled.Text`
  ${Typography.Responsive.Link}
`;
const MicroText = Styled.Text`
  ${Typography.Responsive.Micro}
`;
const MicroLabelText = Styled.Text`
  ${Typography.Responsive.MicroLabel}
`;
const MicroLinkText = Styled.Text`
  ${Typography.Responsive.MicroLink}
`;

type TextProps = ParentTextProps & {
  children: React.ReactNode;
};

const PageHeading = (props: TextProps) => {
  const responsive = useResponsive();
  return <PageHeadingText {...props} responsive={responsive} />;
};
const Heading1 = (props: TextProps) => {
  const responsive = useResponsive();
  return <Heading1Text {...props} responsive={responsive} />;
};
const Heading2 = (props: TextProps) => {
  const responsive = useResponsive();
  return <Heading2Text {...props} responsive={responsive} />;
};
const Subheading = (props: TextProps) => {
  const responsive = useResponsive();
  return <SubheadingText {...props} responsive={responsive} />;
};
const Label = (props: TextProps) => {
  const responsive = useResponsive();
  return <LabelText {...props} responsive={responsive} />;
};
const Body = (props: TextProps) => {
  const responsive = useResponsive();
  return <BodyText {...props} responsive={responsive} />;
};
const Link = (props: TextProps) => {
  const responsive = useResponsive();
  return <LinkText {...props} responsive={responsive} />;
};
const Micro = (props: TextProps) => {
  const responsive = useResponsive();
  return <MicroText {...props} responsive={responsive} />;
};
const MicroLink = (props: TextProps) => {
  const responsive = useResponsive();
  return <MicroLinkText {...props} responsive={responsive} />;
};
const MicroLabel = (props: TextProps) => {
  const responsive = useResponsive();
  return <MicroLabelText {...props} responsive={responsive} />;
};

const Text = {
  PageHeading,
  Heading1,
  Heading2,
  Subheading,
  Label,
  Body,
  Link,
  Micro,
  MicroLink,
  MicroLabel,
};

export default Text;
