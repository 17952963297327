// Libraries
import React from 'react';

// Supermove
import {FlatList, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight} from '@supermove/styles';

// App
import InventoryRoomItem from '@shared/modules/Inventory/Summary/components/InventoryRoomItem';

const Container = Styled.View`
`;

const EmptyContainer = Styled.View`
  align-items: center;
`;

const EmptyTitle = Styled.H7`
  ${fontWeight(700)}
  color: ${colors.gray.secondary};
`;

const EmptySubtitle = Styled.H7`
  ${fontWeight(500)}
  color: ${colors.gray.secondary};
`;

const InventoryRoomsList = ({
  inventory,
  isDisabled,
  showNotes,
  showWeight,
  showVolume,
  isCartonsVisible,
  isItemsVisible,
}: any) => {
  return (
    <Container>
      <FlatList
        listKey={'inventory-rooms-list'}
        data={inventory.rooms}
        keyExtractor={(room: any) => room.id}
        ItemSeparatorComponent={() => <Space height={12} />}
        renderItem={({item: room}) => (
          <InventoryRoomItem
            room={room}
            isDisabled={isDisabled}
            showNotes={showNotes}
            showWeight={showWeight}
            showVolume={showVolume}
            isCartonsVisible={isCartonsVisible}
            isItemsVisible={isItemsVisible}
          />
        )}
        ListEmptyComponent={() => (
          <EmptyContainer>
            <EmptyTitle>Your room summary is empty.</EmptyTitle>
            <Space height={8} />
          </EmptyContainer>
        )}
      />
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InventoryRoomsList.fragment = gql`
  ${InventoryRoomItem.fragment}

  fragment InventoryRoomsList on Inventory {
    id
    rooms {
      id
      ...InventoryRoomItem
    }
  }
`;

export default InventoryRoomsList;
