export interface DeactivateAccountGrantFormType {
  accountGrantId: string;
}

const edit = ({accountGrantId}: {accountGrantId: string}): DeactivateAccountGrantFormType => {
  return {accountGrantId};
};

const toMutation = (form: DeactivateAccountGrantFormType) => {
  return {
    accountGrantId: form.accountGrantId,
  };
};

const DeactivateAccountGrantForm = {
  edit,
  toMutation,
};

export default DeactivateAccountGrantForm;
