// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {useResponsive} from '@supermove/hooks';

// Components
import Drawer from '@shared/design/components/Drawer';
import LayoutBuilder from '@shared/design/components/Layout/components/LayoutBuilder';
import Pane, {PaneType} from '@shared/design/components/Layout/components/Pane';
import PaneGroup, {PaneGroupType} from '@shared/design/components/Layout/components/PaneGroup';
import PaneRail from '@shared/design/components/Layout/components/PaneRail';
import Line from '@shared/design/components/Line';

const DesktopPanesRow = Styled.View`
  flex-direction: row;
  flex: 1;
`;

export interface MultiPaneLayoutProps<T> extends Omit<PaneGroupType<T>, 'panes'> {
  paneDefinitions: PaneType[];
}

const DesktopLayout = <T,>({paneDefinitions, ...paneGroupProps}: MultiPaneLayoutProps<T>) => {
  const paneGroupPaneDefinitions = paneDefinitions.filter(({isFullHeight}) => !isFullHeight);
  const fullHeightPaneDefinitions = paneDefinitions.filter(({isFullHeight}) => isFullHeight);

  return (
    <DesktopPanesRow>
      <PaneGroup
        {...paneGroupProps}
        paneDefinitions={paneGroupPaneDefinitions.map((paneDefinition) => {
          return {
            ...paneDefinition,
            isOnGrid: paneGroupProps.isGrid && !paneDefinition.railOptions,
          };
        })}
      />
      {fullHeightPaneDefinitions.map((paneDefinition) => {
        return (
          <React.Fragment key={paneDefinition.key}>
            <Line isVertical />
            <Pane {...paneDefinition} />
          </React.Fragment>
        );
      })}
    </DesktopPanesRow>
  );
};

const MobileLayout = <T,>({paneDefinitions, ...paneGroupProps}: MultiPaneLayoutProps<T>) => {
  const formattedPaneDefinitions = paneDefinitions.map((paneDefinition) => {
    return {
      ...paneDefinition,
      isScrollable: false,
    };
  });
  const paneGroupPaneDefinitions = formattedPaneDefinitions.filter(({railOptions}) => !railOptions);
  // While built to handle multiple railed panes, we expect
  // only one railed pane to be present on a layout.
  const railedPaneDefinitions = formattedPaneDefinitions.filter(
    ({railOptions}) => !!railOptions?.mobileDrawer,
  );

  return (
    <React.Fragment>
      <PaneGroup {...paneGroupProps} paneDefinitions={paneGroupPaneDefinitions} />
      {railedPaneDefinitions.map((paneDefinition) => {
        const railOptions = paneDefinition.railOptions!;
        const mobileDrawer = railOptions.mobileDrawer!;

        // When we have a panel that goes into a drawer, we'll always make the
        // content scrollable which will also anchor the header to the top. This
        // is consistent with standard drawer patterns.
        return (
          <React.Fragment key={paneDefinition.key}>
            <Line />
            <PaneRail railOptions={railOptions} />
            <Drawer isOpen={mobileDrawer.isOpen}>
              <Pane {...paneDefinition} isScrollable />
            </Drawer>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

const MultiPaneLayout = <T,>({...props}: MultiPaneLayoutProps<T>) => {
  const responsive = useResponsive();

  return (
    <LayoutBuilder.LayoutContainer>
      {responsive.desktop ? <DesktopLayout {...props} /> : <MobileLayout {...props} />}
    </LayoutBuilder.LayoutContainer>
  );
};

export default MultiPaneLayout;
