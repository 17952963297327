// Libraries

// Supermove
import {Popover, Styled} from '@supermove/components';
import {colors} from '@supermove/styles';

const PopoverContainer = Styled.View<{width?: number; height?: number; maxHeight?: number}>`
  padding: 12px;
  width: ${({width}) => (width ? `${width}px` : undefined)};
  height: ${({height}) => (height ? `${height}px` : undefined)};
  maxHeight: ${({maxHeight}) => (maxHeight ? `${maxHeight}px` : undefined)};
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: ${colors.white};
  overflow: visible;
`;

const PopoverBuilder = {
  PLACEMENT: Popover.Positions,

  Content: Popover.Content,
  PopoverContainer,
};

export default PopoverBuilder;
