// Libraries
// @ts-expect-error TS(7016): Could not find a declaration file for module '@inc... Remove this comment to see the full error message
import {gql} from '@increment/graphql';
import _ from 'lodash';

// Supermove
import {
  EmailModel,
  EmailRecipientModel,
  EmailTemplate,
  EmailTemplateModel,
  ProjectModel,
  Proposal,
} from '@supermove/models';
import {HTML, withFragment} from '@supermove/utils';

// App
import EmailTemplateBodyKind from '@shared/modules/Email/enums/EmailTemplateBodyKind';
import EmailRecipientForm from '@shared/modules/Email/forms/EmailRecipientForm';
import {addBodyToForwardedMessage, addForwardToBody} from '@shared/modules/Email/utils/fowarding';
import {parseJsonRecipients} from '@shared/modules/Email/utils/parseJsonRecipients';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';

interface EmailRecipientFormType {
  email: string;
}

export interface EmailRecipientOptionType {
  value?: string;
  label?: string;
  email?: string;
}

export interface EmailFormToFormType {
  organizationId: string;
  customerId: string;
  senderId: string;
  attachmentIds: string[];
  emailTemplateId: string;
  threadId?: string;
  kind: string;
  toEmailRecipientForms: EmailRecipientFormType[];
  ccEmailRecipientForms: EmailRecipientFormType[];
  bccEmailRecipientForms: EmailRecipientFormType[];
  subject: string;
  body: string;
  documentTemplateKinds: string[];

  // Private
  isShowingCc: boolean;
  isShowingBcc: boolean;
  toEmailRecipientValues: string[];
  ccEmailRecipientValues: string[];
  bccEmailRecipientValues: string[];
  searchInputValue: string;
  emailRecipientOptions: EmailRecipientOptionType[];
  bodyKind?: string;
  htmlToKeepWithForwarding?: string;
}

// This might be different later, for now it's the same
export type EmailFormType = EmailFormToFormType;

const getRoleEmailRecipientForms = withFragment<
  {role?: string; project: ProjectModel},
  EmailRecipientFormType[] | null,
  unknown
>(
  ({role, project}) => {
    const projectEmailRecipient = _.find(
      project.projectEmailRecipients,
      (recipient) => _.toLower(role) === _.toLower(recipient.role),
    );
    const email = projectEmailRecipient?.email;
    return email
      ? email
          .split(',')
          .map((emailAddress: any) => EmailRecipientForm.new({email: emailAddress.trim()}))
      : null;
  },
  gql`
    fragment EmailForm_getRoleEmailRecipientForms on Project {
      id
      projectEmailRecipients {
        role
        email
      }
    }
  `,
);

const getEmailRecipientForms = withFragment<
  {emailTemplateRecipients: EmailRecipientModel[]; project: ProjectModel},
  EmailRecipientFormType[],
  unknown
>(
  ({emailTemplateRecipients, project}) => {
    if (!emailTemplateRecipients) {
      return [];
    }
    const emailRecipientForms = emailTemplateRecipients.flatMap((emailTemplateRecipient: any) => {
      const {email, user, role} = emailTemplateRecipient;
      if (role) {
        return getRoleEmailRecipientForms({role, project});
      }
      if (user && user.email) {
        // Make sure an employee is active
        if (
          UserRole.OFFICE_ROLES_PLUS_SUPER.includes(user.role) &&
          user.status !== UserStatus.ACTIVE
        ) {
          return [];
        }
        return EmailRecipientForm.new({email: user.email});
      }
      if (email) {
        return EmailRecipientForm.new({email});
      }
      return null;
    });

    return emailRecipientForms.filter((form: any) => !!form) as EmailRecipientFormType[];
  },
  gql`
    ${getRoleEmailRecipientForms.fragment}

    fragment EmailForm_getEmailRecipientForms_Project on Project {
      id
      ...EmailForm_getRoleEmailRecipientForms
    }

    fragment EmailForm_getEmailRecipientForms_EmailTemplateRecipient on EmailTemplateRecipient {
      email
      role
      user {
        id
        email
        status
      }
    }
  `,
);

const getEmailRecipientValues = withFragment<
  {emailTemplateRecipients: EmailRecipientModel[]},
  string[],
  unknown
>(
  ({emailTemplateRecipients}) => {
    if (!emailTemplateRecipients) {
      return [];
    }
    const emailRecipientValues = emailTemplateRecipients.map((emailTemplateRecipient) => {
      const {email, user, role} = emailTemplateRecipient;
      const userEmail = user ? user.email : null;
      return role || email || userEmail;
    });
    return emailRecipientValues.filter((value) => !!value) as string[];
  },
  gql`
    fragment EmailForm_getEmailRecipientValues on EmailTemplateRecipient {
      email
      role
      user {
        id
        email
      }
    }
  `,
);

const RECIPIENT_DISPLAY_VALUES = {
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_COORDINATOR]: 'Coordinator',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_CUSTOMER]: 'Customer',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_SALESPERSON]: 'Salesperson',
  [EmailTemplate.RECIPIENT_VALUES.PROJECT_BRANCH]: 'Branch Notification Emails',
  [EmailTemplate.RECIPIENT_VALUES.COMPANY_NOTIFICATION_EMAILS]: 'Company Notification Emails',
  [EmailTemplate.RECIPIENT_VALUES.ADDITIONAL_SALESPERSON_EMAILS]:
    'Project Additional Salesperson Emails',
};

const getDisplayRole = (role: any) => {
  return _.get(RECIPIENT_DISPLAY_VALUES, role);
};

// TODO(dan) Waiting on ProjectEmailTemplate to be setup. ProjectEmailTemplate should
// have projectEmailTemplateRecipients which should have email template recipients plus
// projectEmailRecipients.
const getEmailRecipientOptions = withFragment<
  {project: ProjectModel; emailTemplate?: EmailTemplateModel},
  EmailRecipientOptionType[],
  unknown
>(
  ({project, emailTemplate}) => {
    const emailRecipientOptions: EmailRecipientOptionType[] = project.projectEmailRecipients.map(
      ({role, email, label}) => {
        return {
          value: role || email,
          label: `${role ? `${getDisplayRole(role)}: ` : ''}${label} <${email}>`,
          email,
        };
      },
    );
    if (!emailTemplate) {
      return emailRecipientOptions;
    }
    _.get(emailTemplate, 'toEmailTemplateRecipients', []).forEach(
      (recipient: EmailRecipientOptionType) => {
        if (recipient.email) {
          emailRecipientOptions.push({
            value: recipient.email,
            label: recipient.email as string,
          });
        }
      },
    );
    _.get(emailTemplate, 'ccEmailTemplateRecipients', []).forEach((recipient: any) => {
      if (recipient.email) {
        emailRecipientOptions.push({
          value: recipient.email,
          label: recipient.email,
        });
      }
    });
    _.get(emailTemplate, 'bccEmailTemplateRecipients', []).forEach((recipient: any) => {
      if (recipient.email) {
        emailRecipientOptions.push({
          value: recipient.email,
          label: recipient.email,
        });
      }
    });
    return emailRecipientOptions;
  },
  gql`
    fragment EmailForm_getEmailRecipientOptions on Project {
      id
      projectEmailRecipients {
        role
        label
        email
      }
    }
  `,
);

// This takes in an email, and either finds the matching option in the recipient option or adds it to the list
const getEmailValueAndRecipientOptionsFromNewEmails = (
  emailRecipientForm: EmailRecipientFormType[],
  emailRecipientOptions: EmailRecipientOptionType[],
) => {
  const newRecipientOptions = [...emailRecipientOptions];
  const emailValues = emailRecipientForm
    .map((form) => {
      if (!form.email) {
        return undefined;
      }
      const {email} = form;
      const existingOption = _.find(newRecipientOptions, (option) => option.email === email);
      if (existingOption) {
        return existingOption.value || existingOption.email || email;
      } else {
        newRecipientOptions.push({value: email, label: email});
        return email;
      }
    })
    .filter(Boolean) as string[];
  return {emailValues, newRecipientOptions};
};

const _new = () => ({
  organizationId: undefined,
  customerId: undefined,
  senderId: undefined,
  emailTemplateId: '',
  kind: 'CUSTOM',
  toEmailRecipientForms: [],
  ccEmailRecipientForms: [],
  bccEmailRecipientForms: [],
  subject: '',
  body: '',
  attachmentIds: [],
  documentTemplateKinds: [],

  // Private
  isShowingCc: false,
  isShowingBcc: false,
  toEmailRecipientValues: [],
  ccEmailRecipientValues: [],
  bccEmailRecipientValues: [],
  searchInputValue: '',
  emailRecipientOptions: [],
});

const newFromProject = withFragment<unknown, EmailFormToFormType, unknown>(
  // @ts-expect-error TS(2345): Argument of type '({ project, viewerId }: { projec... Remove this comment to see the full error message
  ({project, viewerId, threadId, subject = ''}) => {
    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewerId,
      emailTemplateId: '',
      threadId,
      kind: 'CUSTOM',
      toEmailRecipientForms: [],
      ccEmailRecipientForms: [],
      bccEmailRecipientForms: [],
      subject,
      body: '',
      attachmentIds: [],
      documentTemplateKinds: [],

      // Private
      isShowingCc: false,
      isShowingBcc: false,
      toEmailRecipientValues: [],
      ccEmailRecipientValues: [],
      bccEmailRecipientValues: [],
      searchInputValue: '',
      emailRecipientOptions: getEmailRecipientOptions({project}),
    };
  },
  gql`
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newFromProject on Project {
      id
      organizationId
      customerId
      ...EmailForm_getEmailRecipientOptions
    }
  `,
);

interface NewForReplyParams {
  project: ProjectModel;
  viewerId: string;
  email: EmailModel;
}

const newForReply = withFragment<NewForReplyParams, EmailFormToFormType, unknown>(
  ({project, viewerId, email}) => {
    const originalToEmailRecipients = parseJsonRecipients(email.toEmailRecipientsJson);

    // If it's from the org, keep the original recipients. If it's to the org, then the from email is who it should be to.
    const toEmailRecipientForms = email.isFromOrganization
      ? originalToEmailRecipients.slice(0, 1).map((email) => EmailRecipientForm.new({email}))
      : [EmailRecipientForm.new({email: email.fromEmail})];
    const recipientOptions = getEmailRecipientOptions({project});
    const {emailValues: toEmailValues, newRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(toEmailRecipientForms, recipientOptions);

    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewerId,
      emailTemplateId: '',
      threadId: email.threadId,
      kind: 'CUSTOM',
      toEmailRecipientForms,
      ccEmailRecipientForms: [],
      bccEmailRecipientForms: [],
      subject: email.subject,
      body: '',
      attachmentIds: [],
      documentTemplateKinds: [],

      // Private
      isShowingCc: false,
      isShowingBcc: false,
      toEmailRecipientValues: toEmailValues,
      ccEmailRecipientValues: [],
      bccEmailRecipientValues: [],
      searchInputValue: '',
      emailRecipientOptions: newRecipientOptions,
    };
  },
  gql`
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newForReply_Project on Project {
      id
      organizationId
      customerId
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newForReply_Email on Email {
      id
      fromEmail
      toEmailRecipientsJson
      subject
      threadId
      isFromOrganization
    }
  `,
);

const newForReplyAll = withFragment<NewForReplyParams, EmailFormToFormType, unknown>(
  ({project, viewerId, email}) => {
    const originalToEmailRecipients = parseJsonRecipients(email.toEmailRecipientsJson);
    const originalCcEmailRecipients = parseJsonRecipients(email.ccEmailRecipientsJson);
    const originalBccEmailRecipients = parseJsonRecipients(email.bccEmailRecipientsJson);

    const replyAllForm = newForReply({
      project,
      viewerId,
      email,
    });

    replyAllForm.toEmailRecipientForms = originalToEmailRecipients.map((email) =>
      EmailRecipientForm.new({email}),
    );
    replyAllForm.ccEmailRecipientForms = originalCcEmailRecipients.map((email) =>
      EmailRecipientForm.new({email}),
    );
    replyAllForm.bccEmailRecipientForms = originalBccEmailRecipients.map((email) =>
      EmailRecipientForm.new({email}),
    );
    replyAllForm.isShowingCc = originalCcEmailRecipients.length > 0;
    replyAllForm.isShowingBcc = originalBccEmailRecipients.length > 0;

    const {emailValues: ccEmailValues, newRecipientOptions: newCcRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        replyAllForm.ccEmailRecipientForms,
        replyAllForm.emailRecipientOptions,
      );
    const {emailValues: bccEmailValues, newRecipientOptions: newBccRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        replyAllForm.bccEmailRecipientForms,
        newCcRecipientOptions,
      );

    replyAllForm.ccEmailRecipientValues = ccEmailValues;
    replyAllForm.bccEmailRecipientValues = bccEmailValues;
    replyAllForm.emailRecipientOptions = newBccRecipientOptions;

    return replyAllForm;
  },
  newForReply.fragment,
);

const newForForward = withFragment<NewForReplyParams, EmailFormToFormType, unknown>(
  ({project, viewerId, email}) => {
    const forwardForm = newForReply({
      project,
      viewerId,
      email,
    });

    forwardForm.subject = `Fwd: ${email.subject}`;
    forwardForm.body = addForwardToBody(email);
    forwardForm.toEmailRecipientForms = [];
    forwardForm.htmlToKeepWithForwarding = addForwardToBody(email);

    return forwardForm;
  },
  newForReply.fragment,
);

const newFromProjectWithEmailTemplate = withFragment<unknown, EmailFormToFormType, unknown>(
  // @ts-expect-error TS(2345): Argument of type '({ viewerId, project, emailTempl... Remove this comment to see the full error message
  ({viewerId, project, emailTemplate, threadId, subject}) => {
    const toEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.toEmailTemplateRecipients,
    });
    const ccEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.ccEmailTemplateRecipients,
    });
    const bccEmailRecipientValues = getEmailRecipientValues({
      emailTemplateRecipients: emailTemplate.bccEmailTemplateRecipients,
    });

    return {
      organizationId: project.organizationId,
      customerId: project.customerId,
      senderId: viewerId,
      // TODO(dan) Once migrated over to ProjectEmailTemplate, we should be able
      // to remove lodash here and not have to account for the case where no value
      // is returned for emailTemplateAttachments.
      attachmentIds: _.get(emailTemplate, 'emailTemplateAttachments', []).map(
        (emailTemplateAttachment: any) => emailTemplateAttachment.attachment.id,
      ),
      emailTemplateId: emailTemplate.id,
      threadId,
      kind: emailTemplate.kind,
      toEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.toEmailTemplateRecipients,
        project,
      }),
      ccEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.ccEmailTemplateRecipients,
        project,
      }),
      bccEmailRecipientForms: getEmailRecipientForms({
        emailTemplateRecipients: emailTemplate.bccEmailTemplateRecipients,
        project,
      }),
      subject: subject ?? emailTemplate.subject,
      body: emailTemplate.body,
      documentTemplateKinds: [],
      // Private
      isShowingCc: (ccEmailRecipientValues as any).length > 0,
      isShowingBcc: (bccEmailRecipientValues as any).length > 0,
      toEmailRecipientValues,
      ccEmailRecipientValues,
      bccEmailRecipientValues,
      searchInputValue: '',
      emailRecipientOptions: getEmailRecipientOptions({project, emailTemplate}),
      bodyKind: emailTemplate.bodyKind,
    };
  },
  gql`
    ${EmailTemplate.getCustomRecipientDropdownOptions.fragment}
    ${Proposal.getProjectTypeConfirmationStepsShownByDefault.fragment}
    ${getEmailRecipientForms.fragment}
    ${getEmailRecipientValues.fragment}
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newFromProjectWithEmailTemplate_Project on Project {
      id
      organizationId
      customerId
      organization {
        id
      }
      projectType {
        id
        ...Proposal_getProjectTypeConfirmationStepsShownByDefault
      }
      ...EmailForm_getEmailRecipientForms_Project
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_EmailTemplate on EmailTemplate {
      id
      kind
      bodyKind
      subject
      body
      toEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      ccEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      bccEmailTemplateRecipients {
        ...EmailForm_getEmailRecipientForms_EmailTemplateRecipient
        ...EmailForm_getEmailRecipientValues
      }
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
      ...EmailTemplate_getCustomRecipientDropdownOptions
    }
  `,
);

const alterFormWithTemplate = withFragment<
  {emailTemplate: EmailTemplateModel; form: EmailFormType; project: ProjectModel; body: string},
  EmailFormToFormType,
  unknown
>(
  ({emailTemplate, form, project, body}) => {
    const emailRecipientOptions = getEmailRecipientOptions({project, emailTemplate});
    const {emailValues: toEmailRecipientValues, newRecipientOptions: newToRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        form.toEmailRecipientForms,
        emailRecipientOptions,
      );
    const {emailValues: ccEmailRecipientValues, newRecipientOptions: newCcRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        form.ccEmailRecipientForms,
        newToRecipientOptions,
      );
    const {emailValues: bccEmailRecipientValues, newRecipientOptions: newBccRecipientOptions} =
      getEmailValueAndRecipientOptionsFromNewEmails(
        form.bccEmailRecipientForms,
        newCcRecipientOptions,
      );

    return {
      organizationId: form.organizationId,
      customerId: form.customerId,
      senderId: form.senderId,
      // TODO(dan) Once migrated over to ProjectEmailTemplate, we should be able
      // to remove lodash here and not have to account for the case where no value
      // is returned for emailTemplateAttachments.
      attachmentIds: _.get(emailTemplate, 'emailTemplateAttachments', []).map(
        (emailTemplateAttachment: any) => emailTemplateAttachment.attachment.id,
      ),
      emailTemplateId: emailTemplate.id,
      threadId: form.threadId,
      kind: emailTemplate.kind,
      toEmailRecipientForms: form.toEmailRecipientForms,
      ccEmailRecipientForms: form.ccEmailRecipientForms,
      bccEmailRecipientForms: form.bccEmailRecipientForms,
      subject: form.subject,
      body: form.htmlToKeepWithForwarding
        ? addBodyToForwardedMessage(body, form.htmlToKeepWithForwarding)
        : body,
      documentTemplateKinds: [],
      // Private
      isShowingCc: form.ccEmailRecipientForms.length > 0,
      isShowingBcc: form.bccEmailRecipientForms.length > 0,
      toEmailRecipientValues,
      ccEmailRecipientValues,
      bccEmailRecipientValues,
      searchInputValue: '',
      emailRecipientOptions: newBccRecipientOptions,
      bodyKind: emailTemplate.bodyKind,
      htmlToKeepWithForwarding: form.htmlToKeepWithForwarding,
    };
  },
  gql`
    ${EmailTemplate.getCustomRecipientDropdownOptions.fragment}
    ${Proposal.getProjectTypeConfirmationStepsShownByDefault.fragment}
    ${getEmailRecipientForms.fragment}
    ${getEmailRecipientValues.fragment}
    ${getEmailRecipientOptions.fragment}

    fragment EmailForm_newFromProjectWithEmailTemplate_Project on Project {
      id
      organizationId
      customerId
      organization {
        id
      }
      projectType {
        id
        ...Proposal_getProjectTypeConfirmationStepsShownByDefault
      }
      ...EmailForm_getEmailRecipientForms_Project
      ...EmailForm_getEmailRecipientOptions
    }

    fragment EmailForm_newFromProjectWithEmailTemplate_EmailTemplate on EmailTemplate {
      id
      kind
      bodyKind
      body
      emailTemplateAttachments {
        id
        attachment {
          id
        }
      }
      ...EmailTemplate_getCustomRecipientDropdownOptions
    }
  `,
);

const toForm = ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  emailTemplateId,
  threadId,
  kind,
  toEmailRecipientForms,
  ccEmailRecipientForms,
  bccEmailRecipientForms,
  subject,
  body,
  documentTemplateKinds,
  isShowingCc,
  isShowingBcc,
  toEmailRecipientValues,
  ccEmailRecipientValues,
  bccEmailRecipientValues,
  searchInputValue,
  emailRecipientOptions,
  htmlToKeepWithForwarding,
  bodyKind,
}: EmailFormType) => ({
  organizationId,
  customerId,
  senderId,
  attachmentIds,
  emailTemplateId,
  threadId,
  kind,
  toEmailRecipientForms: toEmailRecipientForms.map((form: any) => EmailRecipientForm.toForm(form)),
  ccEmailRecipientForms: ccEmailRecipientForms.map((form: any) => EmailRecipientForm.toForm(form)),
  bccEmailRecipientForms: bccEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toForm(form),
  ),
  subject,
  body,
  documentTemplateKinds,

  // Private
  isShowingCc,
  isShowingBcc,
  toEmailRecipientValues,
  ccEmailRecipientValues,
  bccEmailRecipientValues,
  searchInputValue,
  emailRecipientOptions,
  htmlToKeepWithForwarding,
  bodyKind,
});

const toMutation = ({
  organizationId,
  customerId,
  senderId,
  threadId,
  attachmentIds,
  kind,
  toEmailRecipientForms,
  ccEmailRecipientForms,
  bccEmailRecipientForms,
  subject,
  body,
  documentTemplateKinds,
  bodyKind,
}: any) => ({
  organizationId,
  customerId,
  senderId,
  threadId,
  attachmentIds,
  kind,
  toEmailRecipientForms: toEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toMutation(form),
  ),
  ccEmailRecipientForms: ccEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toMutation(form),
  ),
  bccEmailRecipientForms: bccEmailRecipientForms.map((form: any) =>
    EmailRecipientForm.toMutation(form),
  ),
  subject,
  body: bodyKind === EmailTemplateBodyKind.HTML ? body : HTML.fixRichTextEditor(body),
  documentTemplateKinds,
});

const EmailForm = {
  new: _new,
  newFromProject,
  newFromProjectWithEmailTemplate,
  newForReply,
  newForReplyAll,
  newForForward,
  toForm,
  toMutation,
  alterFormWithTemplate,
};

export default EmailForm;
