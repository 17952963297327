// Supermove
import {gql} from '@supermove/graphql';
import {useForm, useFormMutation} from '@supermove/hooks';

// App
import CodatIntegrationForm from '@shared/modules/Integration/forms/CodatIntegrationForm';

const useSyncIntegrationPaymentMethodsForCodatIntegrationMutation = ({
  codatIntegrationForm,
  onSuccess,
  onError,
}: any) => {
  const form = useForm({
    initialValues: {
      codatIntegrationForm: CodatIntegrationForm.toForm(codatIntegrationForm),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSyncIntegrationPaymentMethodsForCodatIntegrationMutation.mutation,
    variables: {
      codatIntegrationForm: CodatIntegrationForm.toMutation(form.values.codatIntegrationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSyncIntegrationPaymentMethodsForCodatIntegrationMutation.mutation = gql`
  mutation useSyncIntegrationPaymentMethodsForCodatIntegrationMutation($codatIntegrationForm: CodatIntegrationForm!) {
    response: syncIntegrationPaymentMethodsForCodatIntegration(codatIntegrationForm: $codatIntegrationForm) {
      ${gql.errors}
    }
  }
`;

export default useSyncIntegrationPaymentMethodsForCodatIntegrationMutation;
