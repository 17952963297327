// Supermove
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {OrganizationModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type OrganizationReplyFormFieldsType = {
  organizationId: string;
  replyToKind: string;
  replyToEmail: string;
};

export type OrganizationReplyFormType = Form<{
  organizationReplyToForm: OrganizationReplyFormFieldsType;
}>;

const edit = withFragment<OrganizationModel, OrganizationReplyFormFieldsType, unknown>(
  (organization) => ({
    organizationId: organization.id,
    replyToKind: organization.settings.replyToKind,
    replyToEmail: organization.settings.replyToEmail ?? '',
  }),
  gql`
    fragment OrganizationReplyToForm_edit on Organization {
      id
      settings {
        id
        replyToKind
        replyToEmail
      }
    }
  `,
);

const toMutation = ({
  organizationId,
  replyToKind,
  replyToEmail,
}: OrganizationReplyFormFieldsType) => ({
  organizationId,
  replyToKind,
  replyToEmail,
});

const OrganizationReplyToForm = {
  edit,
  toMutation,
};

export default OrganizationReplyToForm;
