// Libraries
import _ from 'lodash';

// Supermove

// App
import VariableFormat from '@shared/modules/Billing/enums/VariableFormat';

const CITY_TO_RATE_ORIGIN = 'CITY_TO_RATE_ORIGIN';
const CITY_TO_RATE_DESTINATION = 'CITY_TO_RATE_DESTINATION';
const ZIP_CODE_TO_RATE_ORIGIN = 'ZIP_CODE_TO_RATE_ORIGIN';
const ZIP_CODE_TO_RATE_DESTINATION = 'ZIP_CODE_TO_RATE_DESTINATION';
const WEIGHT_DISTANCE_TO_RATE = 'WEIGHT_DISTANCE_TO_RATE';
const WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT = 'WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT';
const DROPDOWN_STRING = 'DROPDOWN_STRING';
const REFERRAL_SOURCE = 'REFERRAL_SOURCE';
const ORIGIN_ZIP_CODE = 'ORIGIN_ZIP_CODE';
const DESTINATION_ZIP_CODE = 'DESTINATION_ZIP_CODE';
const ORIGIN_CITY = 'ORIGIN_CITY';
const DESTINATION_CITY = 'DESTINATION_CITY';
const WEIGHT = 'WEIGHT';
const VARIABLE_MAPPING = 'VARIABLE_MAPPING';

const DATA_TABLE_VARIABLE_ACCESS_KIND_DISPLAY_MAP = {
  [CITY_TO_RATE_ORIGIN]: 'Origin City to Rate',
  [CITY_TO_RATE_DESTINATION]: 'Destination City to Rate',
  [ZIP_CODE_TO_RATE_ORIGIN]: 'Origin Zip Code to Rate',
  [ZIP_CODE_TO_RATE_DESTINATION]: 'Destination Zip Code to Rate',
  [WEIGHT_DISTANCE_TO_RATE]: 'Weight & Distance to Rate',
  [WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT]: 'Weight & Distance to Adjusted Weight',
  [DROPDOWN_STRING]: 'Dropdown String',
  [REFERRAL_SOURCE]: 'Referral Source',
  [ORIGIN_ZIP_CODE]: 'Origin Zip Code to Value',
  [DESTINATION_ZIP_CODE]: 'Destination Zip Code to Value',
  [ORIGIN_CITY]: 'Origin City to Value',
  [DESTINATION_CITY]: 'Destination City to Value',
  [WEIGHT]: 'Weight to Value',
  [VARIABLE_MAPPING]: 'Variable to Value',
};

const VALUES = [
  CITY_TO_RATE_DESTINATION,
  ZIP_CODE_TO_RATE_DESTINATION,
  CITY_TO_RATE_ORIGIN,
  ZIP_CODE_TO_RATE_ORIGIN,
  WEIGHT_DISTANCE_TO_RATE,
  WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT,
  DROPDOWN_STRING,
  REFERRAL_SOURCE,
  ORIGIN_ZIP_CODE,
  DESTINATION_ZIP_CODE,
  ORIGIN_CITY,
  DESTINATION_CITY,
  WEIGHT,
  VARIABLE_MAPPING,
];

const VALUES_WITH_CUSTOM_FORMAT = [
  REFERRAL_SOURCE,
  ORIGIN_ZIP_CODE,
  DESTINATION_ZIP_CODE,
  ORIGIN_CITY,
  DESTINATION_CITY,
  WEIGHT,
  VARIABLE_MAPPING,
];

const VALUES_THAT_SUPPORT_JOB_TYPE_VARIABLES = [VARIABLE_MAPPING, DROPDOWN_STRING];

// TODO(jholston): Filter out new kind
const DROPDOWN_OPTIONS = _.sortBy(
  VALUES.map((value) => ({
    value,
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    label: DATA_TABLE_VARIABLE_ACCESS_KIND_DISPLAY_MAP[value],
  })),
  ({label}) => label,
);

const getDisplayName = (accessKind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return DATA_TABLE_VARIABLE_ACCESS_KIND_DISPLAY_MAP[accessKind];
};

const getIsCityToRate = (accessKind: any) => {
  return [CITY_TO_RATE_ORIGIN, CITY_TO_RATE_DESTINATION].includes(accessKind);
};

const getIsZipCodeToRate = (accessKind: any) => {
  return [ZIP_CODE_TO_RATE_ORIGIN, ZIP_CODE_TO_RATE_DESTINATION].includes(accessKind);
};

const getIsDropdownString = (accessKind: any) => {
  return [DROPDOWN_STRING].includes(accessKind);
};

const getIsReferralSource = (accessKind: any) => {
  return [REFERRAL_SOURCE].includes(accessKind);
};

const ACCESS_KIND_TO_VARIABLE_FORMAT_MAP = {
  [CITY_TO_RATE_ORIGIN]: VariableFormat.CITY_VALUE_CURRENCY,
  [CITY_TO_RATE_DESTINATION]: VariableFormat.CITY_VALUE_CURRENCY,
  [ZIP_CODE_TO_RATE_ORIGIN]: VariableFormat.VALUE_TABLE_CURRENCY,
  [ZIP_CODE_TO_RATE_DESTINATION]: VariableFormat.VALUE_TABLE_CURRENCY,
  [WEIGHT_DISTANCE_TO_RATE]: VariableFormat.CURRENCY,
  [WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT]: VariableFormat.WEIGHT,
  [DROPDOWN_STRING]: VariableFormat.DROPDOWN_STRING,
  [REFERRAL_SOURCE]: null,
};

// These access kinds create isDerived=true variables
const DERIVED_KINDS = [
  WEIGHT_DISTANCE_TO_RATE,
  WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT,
  VARIABLE_MAPPING,
];

const getVariableFormat = (accessKind: any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return ACCESS_KIND_TO_VARIABLE_FORMAT_MAP[accessKind];
};

const getTableReqirements = (accessKind: any) => {
  const baseRequirements = [`All rows and columns must have the same number of values.`];
  switch (accessKind) {
    case CITY_TO_RATE_DESTINATION:
    case CITY_TO_RATE_ORIGIN:
      return [
        ...baseRequirements,
        'City to rate tables must have exactly three columns: city, state, and rate.',
        'The first row should be a header row containing exactly "City, State, Rate", and the rest of the rows should contain values.',
      ];
    case ZIP_CODE_TO_RATE_DESTINATION:
    case ZIP_CODE_TO_RATE_ORIGIN:
      return [
        ...baseRequirements,
        'Zip code to rate tables must have exactly two columns: zip code and rate.',
        'The first row should be a header row containing exactly "Zip Code, Rate" and the rest of the rows should contain values.',
      ];
    case DROPDOWN_STRING:
      return [
        ...baseRequirements,
        'Dropdown string tables must have exactly one column.',
        'The first row should be a header row containing exactly "Values" and the rest of the rows should contain values.',
        'All values must be unique.',
      ];
    case WEIGHT_DISTANCE_TO_RATE:
    case WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT:
      return [
        'Only the first 2 column headers in the tariff table may be empty.',
        `All other rows and columns must have the same number of values.`,
        'All values must be integers.',
      ];
    case REFERRAL_SOURCE:
      return [
        ...baseRequirements,
        'Referral source tables must have exactly two columns.',
        'The first row should be a header row containing exactly "Referral Source, Value", and the rest of the rows should contain values.',
        'All values in the first column must be unique.',
      ];
    case ORIGIN_ZIP_CODE:
    case DESTINATION_ZIP_CODE:
      return [
        ...baseRequirements,
        'Zip Code to Value tables must have exactly two columns.',
        'The first row should be a header row containing exactly "Zip Code, Value", and the rest of the rows should contain values.',
        'All values in the first column must be unique.',
      ];
    case ORIGIN_CITY:
    case DESTINATION_CITY:
      return [
        ...baseRequirements,
        'City to Value tables must have exactly two columns.',
        'The first row should be a header row containing exactly "City, Value", and the rest of the rows should contain values.',
        'All values in the first column must be unique.',
      ];
    case WEIGHT:
      return [
        ...baseRequirements,
        'Weight to Value tables must have exactly two columns.',
        'The first row should be a header row containing exactly "Weight, Value", and the rest of the rows should contain values.',
        'All values in the first column must be unique.',
      ];
    case VARIABLE_MAPPING:
      return [
        ...baseRequirements,
        'Variable to Value tables must have exactly two columns.',
        'The first row should be a header row containing exactly "Variable Value, Output Value", and the rest of the rows should contain values.',
        'All values in the first column must be unique.',
      ];
    default:
      return baseRequirements;
  }
};

export default {
  CITY_TO_RATE_ORIGIN,
  CITY_TO_RATE_DESTINATION,
  ZIP_CODE_TO_RATE_ORIGIN,
  ZIP_CODE_TO_RATE_DESTINATION,
  WEIGHT_DISTANCE_TO_RATE,
  WEIGHT_DISTANCE_TO_ADJUSTED_WEIGHT,
  DROPDOWN_STRING,
  REFERRAL_SOURCE,
  ORIGIN_ZIP_CODE,
  DESTINATION_ZIP_CODE,
  ORIGIN_CITY,
  DESTINATION_CITY,
  WEIGHT,
  VARIABLE_MAPPING,

  VALUES,
  DROPDOWN_OPTIONS,
  VALUES_WITH_CUSTOM_FORMAT,
  VALUES_THAT_SUPPORT_JOB_TYPE_VARIABLES,
  DERIVED_KINDS,

  getDisplayName,
  getIsCityToRate,
  getIsZipCodeToRate,
  getVariableFormat,
  getIsDropdownString,
  getTableReqirements,
  getIsReferralSource,
};
