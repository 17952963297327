// Libraries
import React from 'react';

// Supermove
import {gql} from '@supermove/graphql';
import {JobModel} from '@supermove/models';

// App
import DocumentV2Image from '@shared/modules/Document/components/DocumentV2Image';
import DocumentV2InputCommercialCatalogEquipment from '@shared/modules/Document/components/DocumentV2InputCommercialCatalogEquipment';
import DocumentV2InputCommercialCatalogMaterial from '@shared/modules/Document/components/DocumentV2InputCommercialCatalogMaterial';
import DocumentV2InputGrid from '@shared/modules/Document/components/DocumentV2InputGrid';
import DocumentV2InputList from '@shared/modules/Document/components/DocumentV2InputList';
import DocumentV2InputPerMoverTimesheet from '@shared/modules/Document/components/DocumentV2InputPerMoverTimesheet';
import DocumentV2Paragraph from '@shared/modules/Document/components/DocumentV2Paragraph';
import DocumentV2RadioButtons from '@shared/modules/Document/components/DocumentV2RadioButtons';
import DocumentV2SectionTitle from '@shared/modules/Document/components/DocumentV2SectionTitle';
import DocumentV2Text from '@shared/modules/Document/components/DocumentV2Text';
import DocumentV2TextAreas from '@shared/modules/Document/components/DocumentV2TextAreas';
import DocumentV2TextInputs from '@shared/modules/Document/components/DocumentV2TextInputs';
import DocumentV2TimesheetInformation from '@shared/modules/Document/components/DocumentV2TimesheetInformation';
import DocumentV2Title from '@shared/modules/Document/components/DocumentV2Title';
import DocumentV2ValuationCoverage from '@shared/modules/Document/components/DocumentV2ValuationCoverage';
import DocumentV2ValuationDeclarationTable from '@shared/modules/Document/components/DocumentV2ValuationDeclarationTable';
import InvoiceDocumentV2SummaryInformation from '@shared/modules/Document/components/InvoiceDocumentItemKind/InvoiceDocumentV2SummaryInformation';
import ProjectDocumentV2BillingInformation from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2BillingInformation';
import ProjectDocumentV2CartonSummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2CartonSummary';
import ProjectDocumentV2CustomerSignatureInput from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2CustomerSignatureInput';
import ProjectDocumentV2DocumentHeaderOrganization from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2DocumentHeaderOrganization';
import ProjectDocumentV2GeneralInventoryItems from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GeneralInventoryItems';
import ProjectDocumentV2GeneralInventorySummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GeneralInventorySummary';
import ProjectDocumentV2GenericSignatureInput from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2GenericSignatureInput';
import ProjectDocumentV2InventorySummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2InventorySummary';
import ProjectDocumentV2OrganizationLogo from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2OrganizationLogo';
import ProjectDocumentV2OrganizationSignature from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2OrganizationSignature';
import ProjectDocumentV2SurveyItems from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2SurveyItems';
import ProjectDocumentV2SurveyRoomsSummary from '@shared/modules/Document/components/ProjectDocumentItemKind/ProjectDocumentV2SurveyRoomsSummary';
import DocumentItemKindV2 from '@shared/modules/Document/enums/DocumentItemKindV2';

interface DocumentItemBlocksByCategoryProps {
  job?: JobModel;
  document?: any;
  project?: any;
  invoice?: any;
  item: any;
  form: any;
  field: any;
  index: number;
  isPreview?: boolean;
  isEditable?: boolean;
  isEnabledAutosizeNativeWebviewText?: boolean;
  isEnabledDocumentPerPositionTimesheetTotals: boolean;
  isEnabledDocumentPerPositionTimesheetTimes: boolean;
}

const DocumentItemBlockByCategory = ({
  job,
  document,
  project,
  invoice,
  item,
  form,
  field,
  index,
  isPreview,
  isEditable,
  isEnabledAutosizeNativeWebviewText,
  isEnabledDocumentPerPositionTimesheetTotals,
  isEnabledDocumentPerPositionTimesheetTimes,
}: DocumentItemBlocksByCategoryProps) => {
  const isInEditMode = isEditable && !document.isCompleted && !item.isReadonly;
  const showNotes = item?.input?.showNotes;
  // For weight and volume, the default is true. It should only be false if the input is explicitly set to false.
  const showVolume = item?.input?.showVolume !== false;
  const showWeight = item?.input?.showWeight !== false;

  switch (item.itemKind) {
    case DocumentItemKindV2.SECTION_TITLE:
      return <DocumentV2SectionTitle title={item.text} index={index} />;
    case DocumentItemKindV2.TEXT_TITLE:
      return <DocumentV2Title title={item.text} index={index} />;
    case DocumentItemKindV2.TEXT_PARAGRAPH:
      return <DocumentV2Paragraph paragraph={item.text} index={index} />;
    case DocumentItemKindV2.TEXT:
      return (
        <DocumentV2Text
          html={item.text}
          index={index}
          isEnabledAutosizeNativeWebviewText={isEnabledAutosizeNativeWebviewText}
        />
      );
    case DocumentItemKindV2.IMAGE:
      return (
        <DocumentV2Image
          input={item.input}
          index={index}
          document={document}
          isPreview={isPreview}
        />
      );
    case DocumentItemKindV2.INPUT_RADIO_BUTTONS:
      return (
        <DocumentV2RadioButtons
          input={item.input}
          isRequired={item.isRequired}
          isEditable={isInEditMode}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.INPUT_TEXT_INPUTS:
      return (
        <DocumentV2TextInputs
          input={item.input}
          isRequired={item.isRequired}
          isEditable={isInEditMode}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.INPUT_TEXT_AREAS:
      return (
        <DocumentV2TextAreas
          input={item.input}
          isRequired={item.isRequired}
          isEditable={isInEditMode}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.INPUT_GRID:
      return (
        <DocumentV2InputGrid
          input={item.input}
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.INPUT_LIST:
      return (
        <DocumentV2InputList
          input={item.input}
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          form={form}
          field={field}
        />
      );

    /**
     * PROJECT Document Item
     */
    case DocumentItemKindV2.DOCUMENT_HEADER:
      return (
        <ProjectDocumentV2DocumentHeaderOrganization
          isPreview={isPreview}
          index={index}
          project={project}
        />
      );
    case DocumentItemKindV2.LOGO:
      return (
        <ProjectDocumentV2OrganizationLogo isPreview={isPreview} index={index} project={project} />
      );
    case DocumentItemKindV2.INVENTORY_SUMMARY:
      return (
        <ProjectDocumentV2InventorySummary
          isPreview={isPreview}
          isEditable={isInEditMode}
          project={project}
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      );
    case DocumentItemKindV2.INPUT_SIGNATURE:
      return (
        <ProjectDocumentV2CustomerSignatureInput
          input={item.input}
          isPreview={isPreview}
          isEditable={isInEditMode}
          project={project}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.GENERIC_SIGNATURE:
      return (
        <ProjectDocumentV2GenericSignatureInput
          input={item.input}
          isPreview={isPreview}
          isEditable={isInEditMode}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.ORGANIZATION_SIGNATURE:
      return (
        <ProjectDocumentV2OrganizationSignature
          isPreview={isPreview}
          index={index}
          project={project}
        />
      );
    case DocumentItemKindV2.BILLING_INFORMATION:
      return (
        <ProjectDocumentV2BillingInformation
          isPreview={isPreview}
          index={index}
          project={project}
        />
      );
    case DocumentItemKindV2.SURVEY_ITEMS_SUMMARY:
      return (
        <ProjectDocumentV2SurveyItems
          isPreview={isPreview}
          index={index}
          project={project}
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      );
    case DocumentItemKindV2.SURVEY_ROOMS_SUMMARY:
      return (
        <ProjectDocumentV2SurveyRoomsSummary
          isPreview={isPreview}
          index={index}
          project={project}
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      );
    case DocumentItemKindV2.SURVEY_CARTONS_SUMMARY:
      return (
        <ProjectDocumentV2CartonSummary
          isPreview={isPreview}
          index={index}
          project={project}
          showNotes={showNotes}
          showWeight={showWeight}
          showVolume={showVolume}
        />
      );

    /**
     * JOB Document Item
     */
    case DocumentItemKindV2.INPUT_VALUATION_COVERAGE:
      return (
        <DocumentV2ValuationCoverage
          isPreview={isPreview}
          isEditable={isInEditMode}
          job={job}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_MATERIALS:
      return (
        <DocumentV2InputCommercialCatalogMaterial
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          job={job}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.INPUT_COMMERCIAL_CATALOG_EQUIPMENTS:
      return (
        <DocumentV2InputCommercialCatalogEquipment
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          job={job}
          form={form}
          field={field}
        />
      );
    case DocumentItemKindV2.VALUATION_DECLARATION_TABLE:
      return (
        <DocumentV2ValuationDeclarationTable
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          job={job}
          isCollapsible={isPreview || isEditable}
        />
      );
    case DocumentItemKindV2.INPUT_PER_MOVER_TIMESHEET:
      return (
        // @ts-expect-error This should probably be split into two parts,
        // a preview that doesn't pass in job and a job that does. But don't want to make breaking changes here
        <DocumentV2InputPerMoverTimesheet
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          job={job as JobModel}
          isShowNames={item?.input?.showMoverNames}
          isShowTotals={isEnabledDocumentPerPositionTimesheetTotals}
          isShowTimes={isEnabledDocumentPerPositionTimesheetTimes}
        />
      );
    case DocumentItemKindV2.TIMESHEET_INFORMATION:
      return (
        // @ts-expect-error This should probably be split into two parts,
        // a preview that doesn't pass in job and a job that does. But don't want to make breaking changes here
        <DocumentV2TimesheetInformation
          isPreview={isPreview}
          index={index}
          job={job as JobModel}
          isShowNames={item?.input?.showMoverNames}
          isShowTimes={isEnabledDocumentPerPositionTimesheetTimes}
        />
      );
    case DocumentItemKindV2.GENERAL_INVENTORY_SUMMARY:
      return (
        <ProjectDocumentV2GeneralInventorySummary
          job={job}
          isPreview={isPreview}
          isClientInventory={item?.input?.isClientInventory}
        />
      );
    case DocumentItemKindV2.INVENTORY_ITEMS_SUMMARY:
      return (
        <ProjectDocumentV2GeneralInventoryItems
          job={job}
          isPreview={isPreview}
          isClientInventory={item?.input?.isClientInventory}
        />
      );
    /**
     * INVOICE Document Item
     */
    case DocumentItemKindV2.INVOICE_SUMMARY_INFORMATION:
      return (
        <InvoiceDocumentV2SummaryInformation
          isPreview={isPreview}
          isEditable={isInEditMode}
          index={index}
          invoice={invoice}
        />
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
// See comment in DocumentItemsList, this fragment is not hooked up properly
DocumentItemBlockByCategory.fragment = gql`
  ${DocumentV2Image.fragment}

  fragment DocumentItemBlockByCategory on Document {
    id
    isCompleted
    ...DocumentV2Image
  }
`;

export default DocumentItemBlockByCategory;
