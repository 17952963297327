// Supermove
import {Icon} from '@supermove/components';
import {colors} from '@supermove/styles';

const SEND_EMAIL = 'SEND_EMAIL';
const SEND_SMS = 'SEND_SMS';
const NONE = 'NONE';
const CUSTOM = 'CUSTOM';

const DROPDOWN_OPTIONS = [
  {
    value: NONE,
    label: 'None',
    description:
      'Create a default task that can be completed automatically by AI or manually by a team member.',
  },
  {
    value: SEND_EMAIL,
    label: 'Send email',
    description: 'Generate a draft email to copy and send via your email service.',
  },
  {
    value: SEND_SMS,
    label: 'Send SMS',
    description: 'Generate a draft SMS and send it to the customer.',
  },
  {
    value: CUSTOM,
    label: 'Custom',
    description: 'Generate custom content from the call transcript.',
  },
];

const getActionBadgeLabel = (actionKind: string) => {
  if (!actionKind) {
    return 'No Action';
  }
  switch (actionKind) {
    case SEND_EMAIL:
      return 'Generate Email';
    case SEND_SMS:
      return 'Generate SMS';
    case CUSTOM:
      return 'Generate Content';
  }
};

const getActionBadgeColor = (actionKind: string) => {
  if (!actionKind) {
    return colors.gray.secondary;
  }
  switch (actionKind) {
    case SEND_EMAIL:
      return colors.blue.interactive;
    case SEND_SMS:
      return colors.blue.interactive;
    case CUSTOM:
      return colors.purple.status;
  }
};

const getActionBadgeIcon = (actionKind: string) => {
  if (!actionKind) {
    return Icon.CircleMinus;
  }
  switch (actionKind) {
    case SEND_EMAIL:
      return Icon.Envelope;
    case SEND_SMS:
      return Icon.CommentDots;
    case CUSTOM:
      return Icon.AlignLeft;
  }
};

export default {
  SEND_EMAIL,
  SEND_SMS,
  NONE,
  DROPDOWN_OPTIONS,
  CUSTOM,

  getActionBadgeLabel,
  getActionBadgeColor,
  getActionBadgeIcon,
};
