// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Json, Language, withFragment} from '@supermove/utils';

// App
import ValueForm from '@shared/modules/Billing/forms/ValueForm';
import OrganizationKind from '@shared/modules/Organization/enums/OrganizationKind';
import ConfirmationStepKind from '@shared/modules/Proposal/enums/ConfirmationStepKind';
import TimesheetBlockKind from '@shared/modules/Timesheet/enums/TimesheetBlockKind';
import UserRole from '@shared/modules/User/enums/UserRole';

import getConfirmationLetterSections from './data/getConfirmationLetterSections';
import getConfirmationSteps from './data/getConfirmationSteps';
import getQuoteLetterSections from './data/getQuoteLetterSections';
import getQuoteSteps from './data/getQuoteSteps';

const _getSettingsJsonValue = (organization: any, key: any) => {
  const raw = _.get(organization, `settings.${key}`);
  const parsed = Json.toCamelCaseKeys(Json.parse(raw));
  return parsed;
};

const getNoDemoSlug = (slug: any) => {
  return slug.replace('-demo', '');
};

const getConfirmationStepDescriptionsList = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ steps, organization }: { step... Remove this comment to see the full error message
  ({steps, organization}) => {
    const documentIdentifierToName = organization.jobDocumentTemplates.reduce(
      (map: any, documentTemplate: any) => {
        map[documentTemplate.identifier] = documentTemplate.name;
        return map;
      },
      {},
    );
    return steps.map((step: any, index: any) => {
      switch (step.kind) {
        case ConfirmationStepKind.CONFIRMATION_STEP_DOCUMENT:
        case ConfirmationStepKind.QUOTE_STEP_DOCUMENT: {
          const documentName = documentIdentifierToName[step.value] || 'document';
          return `${index + 1}. Review the ${documentName}.`;
        }
        case ConfirmationStepKind.CONFIRMATION_STEP_ACCEPT_QUOTE:
          return `${index + 1}. View your move information online.`;
        case ConfirmationStepKind.CONFIRMATION_STEP_MAKE_DEPOSIT:
          return `${
            index + 1
          }. Review our deposit policy and leave your deposit through our secure portal.`;
        case ConfirmationStepKind.CONFIRMATION_STEP_SAVE_CREDIT_CARD:
          return `${
            index + 1
          }. Review our cancellation policy and provide your information through our secure portal.`;
        case ConfirmationStepKind.CONFIRMATION_STEP_SIGN_INVENTORY:
          return `${index + 1}. Review your inventory items for accuracy.`;
        case ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT:
          return `${index + 1}. Read and sign a confirmation letter for your move.`;
        case ConfirmationStepKind.CONFIRMATION_STEP_SIGN_DOCUMENT_TEMPLATE:
          return `${index + 1}. Read and sign the ${step.name}.`;
        default:
          return '';
      }
    });
  },
  gql`
    fragment Organization_getConfirmationStepDescriptionsList on Organization {
      id
      jobDocumentTemplates: documentTemplatesByCategory(categories: ["JOB"]) {
        id
        name
        identifier
      }
    }
  `,
);

const getConfirmationStepsDescription = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ steps, organization }: { step... Remove this comment to see the full error message
  ({steps, organization}) => {
    return (getConfirmationStepDescriptionsList as any)({steps, organization}).join('<br>');
  },
  gql`
    ${getConfirmationStepDescriptionsList.fragment}
    fragment Organization_getConfirmationStepsDescription on Organization {
      id
      ...Organization_getConfirmationStepDescriptionsList
    }
  `,
);

const getLocationTypeDropdownOptions = withFragment(
  (organization) => {
    return (organization as any).settings.locationTypes.map((locationType: any) => ({
      label: locationType.name,
      value: locationType.name,
    }));
  },
  gql`
    fragment Organization_getLocationTypeDropdownOptions on Organization {
      id
      settings {
        id
        locationTypes {
          name
        }
      }
    }
  `,
);

const getIsEnabledPayengine = withFragment(
  (organization) => {
    return !!_.get(organization, 'payengineMerchant.isProcessingEnabled');
  },
  gql`
    fragment Organization_getIsEnabledPayengine on Organization {
      id
      payengineMerchant {
        id
        isProcessingEnabled
      }
    }
  `,
);

const _getOrganizationOfficeStaffDropdownOptions = withFragment(
  (organization) => {
    return (organization as any).officeStaff.map((user: any) => {
      return {
        value: Number(user.id),
        label: user.fullName,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        description: organization.name,
      };
    });
  },
  gql`
    fragment Organization_getOrganizationOfficeStaffDropdownOptions on Organization {
      id
      name
      officeStaff: filteredUsersV2(roles: [${UserRole.getGraphqlArgs(
        // @ts-expect-error TS(2345): Argument of type 'readonly ("admin" | "CONTRACTOR"... Remove this comment to see the full error message
        UserRole.OFFICE_ROLES,
      )}]) {
        id
        fullName
      }
    }
  `,
);

const getGreaterOrganizationOfficeStaffDropdownOptions = withFragment(
  (organization) => {
    const allOrganizationOfficeStaffOptions = _.flatten(
      (organization as any).salesStaffOrganizations.map((organization: any) => {
        return _getOrganizationOfficeStaffDropdownOptions(organization);
      }),
    );
    return _.sortBy(allOrganizationOfficeStaffOptions, ['label']);
  },
  gql`
    ${_getOrganizationOfficeStaffDropdownOptions.fragment}
    fragment Organization_getGreaterOrganizationOfficeStaffDropdownOptions on Organization {
      id
      salesStaffOrganizations {
        id
        ...Organization_getOrganizationOfficeStaffDropdownOptions
      }
    }
  `,
);

const getAllOfficeStaffOptions = withFragment(
  (organization) => {
    if ((organization as any).features.isEnabledShowSalespersonsFromAllOrganizations) {
      return getGreaterOrganizationOfficeStaffDropdownOptions(organization);
    }
    return _getOrganizationOfficeStaffDropdownOptions(organization);
  },
  gql`
    ${_getOrganizationOfficeStaffDropdownOptions.fragment}
    ${getGreaterOrganizationOfficeStaffDropdownOptions.fragment}
    fragment Organization_getAllOfficeStaffOptions on Organization {
      id
      features {
        isEnabledShowSalespersonsFromAllOrganizations: isEnabled(
          feature: "SHOW_SALESPERSONS_FROM_ALL_ORGANIZATIONS"
        )
      }
      ...Organization_getOrganizationOfficeStaffDropdownOptions
      ...Organization_getGreaterOrganizationOfficeStaffDropdownOptions
    }
  `,
);

const getSalespersonOptions = withFragment(
  (organization) => {
    return getAllOfficeStaffOptions(organization);
  },
  gql`
    ${getAllOfficeStaffOptions.fragment}
    fragment Organization_getSalespersonOptions on Organization {
      id
      ...Organization_getAllOfficeStaffOptions
    }
  `,
);

const getSalesAppUserDropdownOptions = withFragment(
  (organization) => {
    return (organization as any).salesAppUsers.map((user: any) => {
      return {
        value: user.id,
        label: user.fullName,
        description: _.startCase(_.toLower(user.role)),
      };
    });
  },
  gql`
    fragment Organization_getSalesAppUserDropdownOptions on Organization {
      id
      salesAppUsers {
        id
        fullName
        role
      }
    }
  `,
);

const getSalesVoiceAgentDropdownOptions = withFragment(
  (organization) => {
    return (organization as any).activeVoiceAgents.map((voiceAgent: any) => {
      return {
        value: voiceAgent.id,
        label: voiceAgent.name,
      };
    });
  },
  gql`
    fragment Organization_getSalesVoiceAgentDropdownOptions on Organization {
      id
      activeVoiceAgents {
        id
        name
      }
    }
  `,
);

// This version 2 will include the integration role in the list of salespersons.
// It no longer handles the logic of when to fetch salespersons for all related
// organizations in a company. That logic needs to be handled outside of this
// helper. V2 also stores the id value as a string rather than a number.
const getSalespersonOptionsV2 = withFragment(
  (organization) => {
    const salespeople = (organization as any).salespersons.map((user: any) => {
      return {
        value: _.toNumber(user.id),
        label: user.fullName,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        description: organization.name,
      };
    });
    return salespeople;
  },
  gql`
    fragment Organization_getSalespersonOptionsV2 on Organization {
      id
      name
      salespersons: filteredUsersV2(roles: [${UserRole.getGraphqlArgs([
        // @ts-expect-error TS(2322): Type '"admin" | "CONTRACTOR" | "customer" | "emplo... Remove this comment to see the full error message
        ...UserRole.OFFICE_ROLES,
        // @ts-expect-error TS(2322): Type '"INTEGRATION"' is not assignable to type '"a... Remove this comment to see the full error message
        UserRole.INTEGRATION,
      ])}]) {
        id
        fullName
      }
    }
  `,
);

const getCoordinatorOptions = withFragment(
  (organization) => {
    return (organization as any).coordinators.map((user: any) => {
      return {
        value: Number(user.id),
        label: user.fullName,
      };
    });
  },
  gql`
    fragment Organization_getCoordinatorOptions on Organization {
      id
      coordinators: filteredUsersV2(roles: [${UserRole.getGraphqlArgs(
        // @ts-expect-error TS(2345): Argument of type 'readonly ("admin" | "CONTRACTOR"... Remove this comment to see the full error message
        UserRole.MANAGER_ROLES,
      )}]) {
        id
        fullName
      }
    }
  `,
);

// TODO(dan) Combine getSalespersonOptions and getOfficeStaffDropdownOptions
const getOfficeStaffDropdownOptions = withFragment(
  (organization) => {
    return _getOrganizationOfficeStaffDropdownOptions(organization);
  },
  gql`
    ${_getOrganizationOfficeStaffDropdownOptions.fragment}
    fragment Organization_getOfficeStaffDropdownOptions on Organization {
      id
      ...Organization_getOrganizationOfficeStaffDropdownOptions
    }
  `,
);

const getMoverStaffDropdownOptions = withFragment(
  (organization) => {
    return (organization as any).moverStaff.map((user: any) => {
      return {
        value: Number(user.id),
        label: user.fullName,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        description: organization.name,
      };
    });
  },
  gql`
    fragment Organization_getMoverStaffDropdownOptions on Organization {
      id
      name
      moverStaff: filteredUsersV2(roles: ["${UserRole.EMPLOYEE}"]) {
        id
        fullName
      }
    }
  `,
);

const OptionLabel = Styled.Text`
  ${Typography.Micro}
  color: ${colors.gray.tertiary};
  padding-vertical: 12px;
`;
const getTaskAssigneeDropdownOptions = withFragment(
  (organization) => {
    const officeStaffOptions = _.map(getAllOfficeStaffOptions(organization), (userOption) => ({
      ...userOption,
      value: `USER-${userOption.value}`,
    }));
    const teamsSectionLabel = {
      value: 'TEAMS',
      label: <OptionLabel>Teams</OptionLabel>,
      isDisabled: true,
    };
    const teamOptions = (organization as any).officeTeams.map((team: any) => ({
      value: `TEAM-${team.id}`,
      label: team.name,
    }));
    return [
      ...officeStaffOptions,
      ...(_.isEmpty(teamOptions) ? [] : [teamsSectionLabel, ...teamOptions]),
    ];
  },
  gql`
    ${getAllOfficeStaffOptions.fragment}
    fragment Organization_getTaskAssigneeDropdownOptions on Organization {
      id
      officeTeams {
        id
        name
      }
      ...Organization_getAllOfficeStaffOptions
    }
  `,
);

const getTaskTemplateAssigneeDropdownOptions = withFragment(
  (organization) => {
    // Note(dan) For now, tasks can be directly assigned across different branches for multi branch
    // orgs, but task templates can only be assigned to users of the viewer's branch.
    const officeStaffOptions = _.map(getOfficeStaffDropdownOptions(organization), (userOption) => ({
      ...userOption,
      value: `USER-${userOption.value}`,
    }));
    const teamsSectionLabel = {
      value: 'TEAMS',
      label: <OptionLabel>Teams</OptionLabel>,
      isDisabled: true,
    };
    const teamOptions = (organization as any).officeTeams.map((team: any) => ({
      value: `TEAM-${team.id}`,
      label: team.name,
    }));
    return [
      ...officeStaffOptions,
      ...(_.isEmpty(teamOptions) ? [] : [teamsSectionLabel, ...teamOptions]),
    ];
  },
  gql`
    ${getAllOfficeStaffOptions.fragment}
    ${getOfficeStaffDropdownOptions.fragment}
    fragment Organization_getTaskTemplateAssigneeDropdownOptions on Organization {
      id
      officeTeams {
        id
        name
      }
      ...Organization_getAllOfficeStaffOptions
      ...Organization_getOfficeStaffDropdownOptions
    }
  `,
);

const getStripeAccountId = withFragment(
  (organization) => {
    const credentials = _.get(organization, 'account.stripeConnectAccountCredentials', '{}');
    const json = Json.toCamelCaseKeys(Json.parse(credentials));
    return _.get(json, 'stripeUserId');
  },
  gql`
    fragment Organization_getStripeAccountId on Organization {
      id
      account {
        id
        stripeConnectAccountCredentials
      }
    }
  `,
);

const hasStripeAccountId = withFragment(
  (organization) => {
    return !!getStripeAccountId(organization);
  },
  gql`
    ${getStripeAccountId.fragment}
    fragment Organization_hasStripeAccountId on Organization {
      id
      ...Organization_getStripeAccountId
    }
  `,
);

const getJobFormAdditionalItems = withFragment(
  (organization) => {
    return _getSettingsJsonValue(organization, 'jobFormAdditionalItems');
  },
  gql`
    fragment Organization_getJobFormAdditionalItems on Organization {
      id
      settings {
        id
        jobFormAdditionalItems
      }
    }
  `,
);

const getJobFormAdditionalItemsDefaultValues = withFragment(
  (organization) => {
    const jobFormAdditionalItems = getJobFormAdditionalItems(organization);
    // @ts-expect-error TS(7006): Parameter 'all' implicitly has an 'any' type.
    const reducer = (all, value, key) => ({
      ...all,
      [key]: _.get(value, 'defaultValue'),
    });
    return _.reduce(jobFormAdditionalItems, reducer, {});
  },
  gql`
    ${getJobFormAdditionalItems.fragment}
    fragment Organization_getJobFormAdditionalItemsDefaultValues on Organization {
      id
      ...Organization_getJobFormAdditionalItems
    }
  `,
);

const getJobFormAdditionalItemLabel = withFragment(
  (organization, {key}) => {
    const jobFormAdditionalItems = getJobFormAdditionalItems(organization);
    return _.get(jobFormAdditionalItems, `${key}.label`);
  },
  gql`
    ${getJobFormAdditionalItems.fragment}
    fragment Organization_getJobFormAdditionalItemLabel on Organization {
      id
      ...Organization_getJobFormAdditionalItems
    }
  `,
);

const getJobFormAdditionalItemValue = withFragment(
  (organization, {key, value}) => {
    const jobFormAdditionalItems = getJobFormAdditionalItems(organization);
    const type = _.get(jobFormAdditionalItems, `${key}.type`);
    switch (type) {
      case 'checkbox':
        return Language.yesNo(value);
      case 'text':
        return value;
      default:
        return;
    }
  },
  gql`
    ${getJobFormAdditionalItems.fragment}
    fragment Organization_getJobFormAdditionalItemValue on Organization {
      id
      ...Organization_getJobFormAdditionalItems
    }
  `,
);

const getJobFormCustomValues = withFragment(
  (organization) => {
    return _getSettingsJsonValue(organization, 'jobFormCustomValues');
  },
  gql`
    fragment Organization_getJobFormCustomValues on Organization {
      id
      settings {
        id
        jobFormCustomValues
      }
    }
  `,
);

const getAdditionalSupplies = withFragment(
  (organization) => {
    return _getSettingsJsonValue(organization, 'additionalSupplies');
  },
  gql`
    fragment Organization_getAdditionalSupplies on Organization {
      id
      settings {
        id
        additionalSupplies
      }
    }
  `,
);

const getSupplies = withFragment(
  (organization) => {
    return _getSettingsJsonValue(organization, 'supplies');
  },
  gql`
    fragment Organization_getSupplies on Organization {
      id
      settings {
        id
        supplies
      }
    }
  `,
);

const getNotifications = withFragment(
  (organization) => {
    return _getSettingsJsonValue(organization, 'notifications');
  },
  gql`
    fragment Organization_getNotifications on Organization {
      id
      settings {
        id
        notifications
      }
    }
  `,
);

const getProjectTypes = withFragment(
  (organization) => {
    return (organization as any).projectTypes.map((projectType: any) => {
      return {
        name: projectType.name,
        id: projectType.id,
        isSelected: true,
      };
    });
  },
  gql`
    fragment Organization_getProjectTypes on Organization {
      id
      projectTypes {
        id
        name
      }
    }
  `,
);

const getProjectTypesForMultibranchSlugs = withFragment(
  (organization, slugs) => {
    // Find out the mapping of slugs to organizationKind
    const slugsToOrganizationKind = (organization as any).company.organizations.reduce(
      (acc: any, organization: any) => {
        acc[organization.slug] = organization.kind;
        return acc;
      },
      {},
    );

    // First fetch all the project types for organization
    const projectTypesForMultibranchOrganization = _.orderBy(
      (organization as any).projectTypesForMultibranchOrganization.map((projectType: any) => {
        return {
          name: projectType.name,
          id: projectType.id,
          isSelected: true,
          slug: projectType.organization.slug,
          kind: projectType.organization.kind,
          organizationName: projectType.organization.name,
          organizationIsPrimary: projectType.organization.isPrimary,
        };
      }),
      ['organizationIsPrimary', 'organizationName'],
      ['desc', 'asc'],
    );

    // theres no slugs OR slugs contain ALL_ORGANIZATIONS (no filter case), we return
    if (_.isEmpty(slugs) || slugs.includes('ALL_ORGANIZATIONS')) {
      return projectTypesForMultibranchOrganization;
    }

    // Construct a set organizationKind to be fetched from the list of slugs passed in
    const organizationKindsSet = slugs.reduce((set: any, slug: any) => {
      if (
        slugsToOrganizationKind[slug] &&
        [OrganizationKind.CONTRACTOR, OrganizationKind.BRANCH].includes(
          slugsToOrganizationKind[slug],
        )
      ) {
        set.add(OrganizationKind.MAIN);
      } else {
        set.add(slugsToOrganizationKind[slug]);
      }
      return set;
    }, new Set());

    return projectTypesForMultibranchOrganization.filter((projectType) => {
      return organizationKindsSet.has(projectType.kind);
    });
  },
  gql`
    fragment Organization_getProjectTypesForMultibranchSlugs on Organization {
      id
      company {
        id
        organizations {
          id
          slug
          kind
        }
      }
      projectTypesForMultibranchOrganization {
        id
        name
        organization {
          id
          kind
          slug
          name
          isPrimary
        }
      }
    }
  `,
);

const getSalesPersons = withFragment(
  (organization) => {
    return [
      {
        fullName: 'All',
        id: 0,
        isSelected: true,
      },
      {
        fullName: 'No Salesperson',
        id: null,
        isSelected: true,
      },
      ...(organization as any).salespersons.map((salesperson: any) => ({
        fullName: salesperson.fullName,
        id: salesperson.id,
        isSelected: true,
      })),
    ];
  },
  gql`
    fragment Organization_getSalesPersons on Organization {
      id
      salespersons: filteredUsersV2(roles: [${UserRole.getGraphqlArgs(
        // @ts-expect-error TS(2345): Argument of type 'readonly ("admin" | "CONTRACTOR"... Remove this comment to see the full error message
        UserRole.OFFICE_ROLES,
      )}]) {
        id
        fullName
      }
    }
  `,
);

// TODO(jholston): deprecate with isEnabledUpdatedVariableSettings in favor of makeProjectValueFormsFromProjectTypeVariableSections
const makeProjectValueFormsFromOrganizationVariables = withFragment(
  (organization) => {
    return (organization as any).variablesForProject.map((variable: any) => {
      return ValueForm.newFromVariable(variable);
    });
  },
  gql`
    ${ValueForm.newFromVariable.fragment}

    fragment Organization_makeProjectValueFormsFromOrganizationVariables on Organization {
      id
      variablesForProject {
        id
        ...ValueForm_newFromVariable
      }
    }
  `,
);

// TODO(jholston): deprecate with isEnabledUpdatedVariableSettings in favor of makeProjectValueFormsFromProjectTypeVariableSections
const makeProjectValueFormsFromOrganizationVariablesAndStorageProjectTypeVariables = withFragment(
  (organization, {projectTypeId}) => {
    const {projectTypeVariables} = _.find((organization as any).storageProjectTypes, {
      id: projectTypeId,
    });

    return (organization as any).variablesForProject.map((variable: any) => {
      const projectTypeVariable = _.find(projectTypeVariables, {
        variableId: _.toNumber(variable.id),
      });
      if (projectTypeVariable) {
        return ValueForm.toForm(ValueForm.newFromProjectTypeVariable(projectTypeVariable));
      }
      return ValueForm.toForm(ValueForm.newFromVariable(variable));
    });
  },
  gql`
    ${ValueForm.newFromVariable.fragment}
    ${ValueForm.newFromProjectTypeVariable.fragment}

    fragment Organization_makeProjectValueFormsFromOrganizationVariablesAndStorageProjectTypeVariables on Organization {
      id
      storageProjectTypes: projectTypesForCategory(category: "STORAGE") {
        id
        projectTypeVariables {
          id
          variableId
          ...ValueForm_newFromProjectTypeVariable
        }
      }
      variablesForProject {
        id
        ...ValueForm_newFromVariable
      }
    }
  `,
);

const makeProjectValueFormsFromProjectTypeVariableSections = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ projectType, existingValueFor... Remove this comment to see the full error message
  ({projectType, existingValueForms, isEdit}) => {
    const {projectTypeVariables, projectTypeVariableSections} = projectType;
    const hasExistingValueForms = !_.isEmpty(existingValueForms);

    return isEdit && hasExistingValueForms
      ? existingValueForms
      : _.flatMap(projectTypeVariableSections, (projectTypeVariableSection) => {
          return _.map(projectTypeVariableSection.orderedVariables, (variable) => {
            const projectTypeVariable = _.find(projectTypeVariables, {
              variableId: _.toNumber(variable.id),
            });

            if (projectTypeVariable) {
              return ValueForm.toForm(
                ValueForm.newFromProjectTypeVariable(projectTypeVariable, {
                  variableSectionId: _.toNumber(projectTypeVariableSection.id),
                }),
              );
            }

            return ValueForm.toForm(
              ValueForm.newFromVariable(variable, {
                variableSectionId: _.toNumber(projectTypeVariableSection.id),
              }),
            );
          });
        });
  },
  gql`
    ${ValueForm.newFromVariable.fragment}
    ${ValueForm.newFromProjectTypeVariable.fragment}

    fragment Organization_makeProjectValueFormsFromProjectTypeVariableSections on ProjectType {
      id
      projectTypeVariableSections {
        id
        orderedVariables {
          id
          ...ValueForm_newFromVariable
        }
      }
      projectTypeVariables {
        id
        variableId
        ...ValueForm_newFromProjectTypeVariable
      }
    }
  `,
);

const makeProjectValueFormsFromProjectValues = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ project }: { project: any; })... Remove this comment to see the full error message
  ({project}) => {
    const {projectType, values} = project;
    const {projectTypeVariableSections} = projectType;
    return _.flatMap(projectTypeVariableSections, (projectTypeVariableSection) => {
      return _.reduce(
        projectTypeVariableSection.orderedVariables,
        (valueForms, variable) => {
          const value = _.find(
            values,
            (value) => value.variable?.identifier === variable.identifier,
          );

          if (value) {
            valueForms.push(
              // @ts-expect-error TS(2345): Argument of type 'ValueForm' is not assignable to ... Remove this comment to see the full error message
              ValueForm.edit(value, {
                variableSectionId: _.toNumber(projectTypeVariableSection.id),
              }),
            );
          }
          return valueForms;
        },
        [],
      );
    });
  },
  gql`
    ${ValueForm.edit.fragment}

    fragment Organization_makeProjectValueFormsFromProjectValues on Project {
      id
      projectType {
        id
        projectTypeVariableSections {
          id
          orderedVariables {
            id
            identifier
          }
        }
      }
      values {
        id
        ...ValueForm_edit
      }
    }
  `,
);

const makeJobValueFormsFromJobTypeVariableSections = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ jobType, existingValueForms, ... Remove this comment to see the full error message
  ({jobType, existingValueForms, isEdit}) => {
    const {jobTypeVariables, jobTypeVariableSections} = jobType;
    const hasExistingValueForms = !_.isEmpty(existingValueForms);
    return isEdit && hasExistingValueForms
      ? existingValueForms
      : _.flatMap(jobTypeVariableSections, (jobTypeVariableSection) => {
          return _.map(jobTypeVariableSection.orderedVariables, (variable) => {
            const jobTypeVariable = _.find(jobTypeVariables, {
              variableId: _.toNumber(variable.id),
            });

            if (jobTypeVariable) {
              return ValueForm.toForm(
                ValueForm.newFromJobTypeVariable(jobTypeVariable, {
                  variableSectionId: _.toNumber(jobTypeVariableSection.id),
                }),
              );
            }

            return ValueForm.toForm(
              ValueForm.newFromVariable(variable, {
                variableSectionId: _.toNumber(jobTypeVariableSection.id),
              }),
            );
          });
        });
  },
  gql`
    ${ValueForm.newFromVariable.fragment}
    ${ValueForm.newFromJobTypeVariable.fragment}

    fragment Organization_makeJobValueFormsFromJobTypeVariableSections on JobType {
      id
      jobTypeVariableSections {
        id
        orderedVariables {
          id
          ...ValueForm_newFromVariable
        }
      }
      jobTypeVariables {
        id
        variableId
        ...ValueForm_newFromJobTypeVariable
      }
    }
  `,
);

const makeJobValueFormsFromJobValues = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ job }: { job: any; }) => neve... Remove this comment to see the full error message
  ({job}) => {
    const {jobType, values} = job;
    const {jobTypeVariableSections} = jobType;
    return _.flatMap(jobTypeVariableSections, (jobTypeVariableSection) => {
      return _.reduce(
        jobTypeVariableSection.orderedVariables,
        (valueForms, variable) => {
          const value = _.find(
            values,
            (value) => value.variable?.identifier === variable.identifier,
          );

          if (value) {
            valueForms.push(
              // @ts-expect-error TS(2345): Argument of type 'ValueForm' is not assignable to ... Remove this comment to see the full error message
              ValueForm.edit(value, {
                variableSectionId: _.toNumber(jobTypeVariableSection.id),
              }),
            );
          }
          return valueForms;
        },
        [],
      );
    });
  },
  gql`
    ${ValueForm.edit.fragment}

    fragment Organization_makeJobValueFormsFromJobValues on Job {
      id
      jobType {
        id
        jobTypeVariableSections {
          id
          orderedVariables {
            id
            identifier
          }
        }
      }
      values {
        id
        ...ValueForm_edit
      }
    }
  `,
);

const getOrganizationBySlugsWithParent = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ slugs, organization }: { slug... Remove this comment to see the full error message
  ({slugs, organization}) => {
    return _.sortBy(
      organization.company.organizations.filter((companyOrganization: any) => {
        if (slugs.includes('ALL_ORGANIZATIONS')) {
          return true;
        } else {
          if (companyOrganization.isPrimary || slugs.includes(companyOrganization.slug)) {
            return true;
          }
          return false;
        }
      }),
      [(organization) => !organization.isPrimary, 'name'],
    );
  },
  gql`
    fragment Organization_getOrganizationBySlugsWithParent on Organization {
      id
      company {
        id
        organizations {
          id
          name
          slug
          isPrimary
        }
      }
    }
  `,
);

const getCompanySettingsMoverPositionsByRole = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ role, organization }: { role:... Remove this comment to see the full error message
  ({role, organization}) => {
    return _.sortBy(
      organization.companySettings.moverPositions.filter((moverPosition: any) => {
        // If role is empty, we return all
        if (!role) {
          return true;
        }
        return moverPosition.role === role;
      }),
      ['name'],
    );
  },
  gql`
    fragment Organization_getCompanySettingsMoverPositionsByRole on Organization {
      id
      companySettings {
        moverPositions {
          id
          name
          role
        }
      }
    }
  `,
);

const getCompanySettingsAllMoverPositionsByRole = withFragment(
  // @ts-expect-error TS(2345): Argument of type '({ role, organization }: { role:... Remove this comment to see the full error message
  ({role, organization}) => {
    return _.sortBy(
      organization.companySettings.allMoverPositions.filter((moverPosition: any) => {
        // If role is empty, we return all
        if (!role) {
          return true;
        }
        return moverPosition.role === role;
      }),
      ['name'],
    );
  },
  gql`
    fragment Organization_getCompanySettingsAllMoverPositionsByRole on Organization {
      id
      companySettings {
        allMoverPositions {
          id
          name
          role
          isDeleted
        }
      }
    }
  `,
);

const getTimesheetBlockKinds = withFragment(
  (organization) => {
    if ((organization as any).features.isEnabledTimesheetBlockKindWait) {
      return TimesheetBlockKind.Values;
    }
    return _.without(TimesheetBlockKind.Values, TimesheetBlockKind.WAIT);
  },
  gql`
    fragment Organization_getTimesheetBlockKinds on Organization {
      id
      features {
        isEnabledTimesheetBlockKindWait: isEnabled(feature: "TIMESHEET_BLOCK_KIND_WAIT")
      }
    }
  `,
);

const getBranchTypeForOrganization = withFragment(
  (organization) => {
    switch ((organization as any).kind) {
      case OrganizationKind.MAIN:
        return 'Main';
      case OrganizationKind.BRANCH:
      case OrganizationKind.CONTRACTOR:
        return 'Corporate';
      case OrganizationKind.FRANCHISE:
        return 'Franchise';
      default:
        return '';
    }
  },
  gql`
    fragment Organization_getBranchTypeForOrganization on Organization {
      id
      kind
    }
  `,
);

const Organization = {
  getConfirmationStepsDescription,
  getAllOfficeStaffOptions,
  getCoordinatorOptions,
  getSalespersonOptions,
  getSalespersonOptionsV2,
  getOfficeStaffDropdownOptions,
  getMoverStaffDropdownOptions,
  getGreaterOrganizationOfficeStaffDropdownOptions,
  getLocationTypeDropdownOptions,
  getTaskAssigneeDropdownOptions,
  getTaskTemplateAssigneeDropdownOptions,
  getSalesAppUserDropdownOptions,
  getSalesVoiceAgentDropdownOptions,

  // Payments
  getIsEnabledPayengine,
  getStripeAccountId,
  hasStripeAccountId,

  // JobForm
  getJobFormAdditionalItems,
  getJobFormAdditionalItemsDefaultValues,
  getJobFormAdditionalItemLabel,
  getJobFormAdditionalItemValue,
  getJobFormCustomValues,

  getAdditionalSupplies,
  getSupplies,

  // ValueForm
  makeProjectValueFormsFromOrganizationVariables,
  makeProjectValueFormsFromOrganizationVariablesAndStorageProjectTypeVariables,
  makeProjectValueFormsFromProjectTypeVariableSections,
  makeProjectValueFormsFromProjectValues,
  makeJobValueFormsFromJobTypeVariableSections,
  makeJobValueFormsFromJobValues,

  // This function is used in old crew app files and will require
  // a larger refactor to update.
  // DEPRECATED: Use getTimesheetBlockKinds instead
  getTimeRangeKinds: (organization: any) => {
    // Everyone shares these 3 kinds.
    const kinds = ['WORK', 'DRIVE', 'BREAK'];

    switch (getNoDemoSlug(organization.slug)) {
      case 'onebigman':
        return _.concat(kinds, 'WAIT');
      default:
        return kinds;
    }
  },

  // --------------------------------------------------
  // Org-specific settings / data
  // --------------------------------------------------
  getConfirmationLetterSections,
  getConfirmationSteps,
  getNoDemoSlug,
  getQuoteLetterSections,
  getQuoteSteps,
  getProjectTypes,
  getProjectTypesForMultibranchSlugs,
  getOrganizationBySlugsWithParent,
  getSalesPersons,
  getNotifications,
  getCompanySettingsMoverPositionsByRole,
  getCompanySettingsAllMoverPositionsByRole,
  getTimesheetBlockKinds,
  getBranchTypeForOrganization,
};

export default Organization;
