import {gql} from '@supermove/graphql';
import {ConversationModel, ConversationStatusModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

export type ConversationFormType = {
  id: string;
  projectId: string;
  organizationId: string;
  isPinned: boolean;
  status: ConversationStatusModel;
};

export type WrappedConversationFormType = {
  conversationForm: ConversationFormType;
};

const edit = withFragment<ConversationModel, ConversationFormType, unknown>(
  (conversation) => ({
    id: conversation.id,
    projectId: conversation.project.id,
    organizationId: conversation.organization.id,
    isPinned: conversation.isPinned,
    status: conversation.status,
  }),
  gql`
    fragment ConversationForm_edit on Conversation {
      id
      status
      isPinned
      project {
        id
      }
      organization {
        id
      }
    }
  `,
);

const toMutation = (form: ConversationFormType) => {
  return {
    conversationId: form.id,
    projectId: form.projectId,
    organizationId: form.organizationId,
    status: form.status,
    isPinned: form.isPinned,
  };
};

const ConversationForm = {
  edit,
  toMutation,
};

export default ConversationForm;
