// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {Formula, FormulaModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import {EvaluateFormulaStringInput} from '@shared/formulas/src/library/EvaluateFormulaStringInput';
import evaluateFormulaString from '@shared/formulas/src/logic/evaluateFormulaString';

const getAstJson = ({formulaString}: {formulaString: string}) => {
  const {formulaAST} = evaluateFormulaString(new EvaluateFormulaStringInput(formulaString));
  return formulaAST;
};

export interface FormulaFormType {
  identifier: string;
  organizationId: string;
  name: string;
  description: string;
  astJson: any;
  createdById: null | string;
  updatedById: string;
  formulaString: string;
}

const _new = ({
  organizationId,
  userId,
}: {
  organizationId: string;
  userId: string;
}): FormulaFormType => ({
  identifier: '',
  organizationId,
  name: '',
  description: '',
  astJson: {},
  createdById: userId,
  updatedById: userId,

  // Private
  formulaString: '',
});

const edit = withFragment(
  (
    formula: FormulaModel,
    {
      userId,
    }: {
      userId: string;
    },
  ): FormulaFormType => ({
    identifier: formula.identifier,
    organizationId: formula.organizationId,
    name: formula.name,
    description: formula.description,
    astJson: formula.astJson,
    createdById: null,
    updatedById: userId,
    // Private
    formulaString: Formula.getFormulaString(formula),
  }),
  gql`
    ${Formula.getFormulaString.fragment}
    fragment FormulaForm_edit on Formula {
      id
      identifier
      organizationId
      name
      description
      astJson
      ...Formula_getFormulaString
    }
  `,
);

export type FormulaFormTypeToForm = FormulaFormType;

const toForm = ({
  identifier,
  organizationId,
  name,
  description,
  astJson,
  formulaString,
  createdById,
  updatedById,
}: FormulaFormType): FormulaFormTypeToForm => {
  return {
    identifier,
    organizationId,
    name,
    description,
    astJson: getAstJson({formulaString}),
    createdById,
    updatedById,

    // Private
    formulaString,
  };
};

const toMutation = ({
  identifier,
  organizationId,
  name,
  description,
  astJson,
  formulaString,
  createdById,
  updatedById,
}: FormulaFormTypeToForm) => {
  return {
    identifier: identifier || _.toUpper(_.snakeCase(name)),
    organizationId,
    name,
    description,
    astJson: JSON.stringify(getAstJson({formulaString})),
    createdById,
    updatedById,
  };
};

const FormulaForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default FormulaForm;
