// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Icon, ScrollView, Space, Styled} from '@supermove/components';
import {ResponsiveType, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

// Components
import IconButton from '@shared/design/components/IconButton';
import LayoutBuilder from '@shared/design/components/Layout/components/LayoutBuilder';
import PaneRail, {
  RailOptions,
  WithRail,
  WithoutRail,
  RAIL_WIDTH,
} from '@shared/design/components/Layout/components/PaneRail';
import Line from '@shared/design/components/Line';

const PaneContainer = Styled.View<{
  width?: number;
  isScrollable: boolean;
  responsive: ResponsiveType;
}>`
  flex-direction: row;
  ${({responsive, width, isScrollable}) =>
    responsive.desktop
      ? `${width ? `width: ${width}px; max-width: ${width}px;` : 'flex: 1; min-width: 280px;'}`
      : `${isScrollable ? 'flex: 1;' : ''}
    `}
`;

const ContentContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: ${({responsive}) => (responsive.desktop ? 1 : 'auto')};
  flex-direction: column;
`;

const HeaderContainer = Styled.View`
  padding: 16px;
  background-color: ${colors.white};
`;

const TitleRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

export type PaneType = {
  key: string;
  contentContainerStyle?: ViewStyleProp;
  titleText?: string;
  titleComponent?: React.ReactElement;
  headerActionsComponent?: React.ReactElement;
  headerContentComponent?: React.ReactElement;
  headerContainerStyle?: ViewStyleProp;
  bodyContentComponent?: React.ReactElement;
  width?: number;
  isOnGrid?: boolean;
  isHidden?: boolean;
  isScrollable?: boolean;
  scrollViewStyle?: ViewStyleProp;
  style?: ViewStyleProp;
  // isFullHeight is used to separate panes that will take
  // up the full height of the window by being placed adjacent
  // to rather than under the header.
  isFullHeight?: boolean;
  // Use for tabs when panes convert to tabs on mobile
  tabLabel?: string;
} & (WithRail | WithoutRail);

type PaneContentType = Omit<PaneType, 'key'>;

const Header = ({
  titleText,
  titleComponent,
  headerActionsComponent,
  headerContentComponent,
  headerContainerStyle,
  railOptions,
}: {
  titleText?: string;
  titleComponent?: React.ReactElement;
  headerActionsComponent?: React.ReactElement;
  headerContentComponent?: React.ReactElement;
  headerContainerStyle?: ViewStyleProp;
  railOptions?: RailOptions;
}) => {
  const responsive = useResponsive();
  const hasTitleContent = !!titleText || !!titleComponent || !!headerActionsComponent;
  const mobileDrawer = railOptions?.mobileDrawer;
  const showCloseButton = (!responsive.desktop && !!mobileDrawer) || railOptions?.handleClose;

  return (
    <HeaderContainer style={headerContainerStyle}>
      <TitleRow>
        {!!titleText && (
          <React.Fragment>
            <LayoutBuilder.TitleText>{titleText}</LayoutBuilder.TitleText>
            <Space width={8} />
          </React.Fragment>
        )}
        {!!titleComponent && (
          <React.Fragment>
            {titleComponent}
            <Space width={8} />
          </React.Fragment>
        )}
        {(!!headerActionsComponent || !!railOptions) && (
          <React.Fragment>
            <Space flex={1} />
            {!!headerActionsComponent && headerActionsComponent}
            {!!headerActionsComponent && showCloseButton && <Space width={16} />}
            {showCloseButton && (
              <IconButton
                source={Icon.Xmark}
                onPress={() => {
                  mobileDrawer?.handleClose();
                  railOptions?.handleClose?.();
                }}
                isSecondary
              />
            )}
          </React.Fragment>
        )}
      </TitleRow>
      {!!headerContentComponent && (
        <React.Fragment>
          {hasTitleContent && <Space height={16} />}
          {headerContentComponent}
        </React.Fragment>
      )}
    </HeaderContainer>
  );
};

const PaneContent = ({
  contentContainerStyle,
  titleText,
  titleComponent,
  headerActionsComponent,
  headerContentComponent,
  headerContainerStyle,
  bodyContentComponent,
  railOptions,
  isOnGrid = false,
  isScrollable = false,
  scrollViewStyle,
}: PaneContentType) => {
  const responsive = useResponsive();

  return (
    <ContentContainer responsive={responsive} style={contentContainerStyle}>
      {(titleText || titleComponent || headerContentComponent) && (
        <React.Fragment>
          <Header
            titleText={titleText}
            titleComponent={titleComponent}
            headerActionsComponent={headerActionsComponent}
            headerContentComponent={headerContentComponent}
            headerContainerStyle={headerContainerStyle}
            railOptions={railOptions}
          />
          <Line />
        </React.Fragment>
      )}
      {isScrollable ? (
        <ScrollView
          style={[{flex: 1, backgroundColor: colors.gray.background}, scrollViewStyle]}
          contentContainerStyle={
            isOnGrid && {
              marginRight: 16,
              paddingVertical: 16,
            }
          }
        >
          {bodyContentComponent}
        </ScrollView>
      ) : (
        bodyContentComponent
      )}
    </ContentContainer>
  );
};

const Pane = ({
  titleText,
  titleComponent,
  headerActionsComponent,
  headerContentComponent,
  headerContainerStyle,
  bodyContentComponent,
  railOptions,
  width,
  isFullHeight,
  isOnGrid = false,
  isHidden = false,
  isScrollable = false,
  style,
}: PaneType) => {
  const responsive = useResponsive();
  const isRailed = !!railOptions;
  const hasRailButNoSelection = isRailed && !_.find(railOptions.actions, 'isSelected');
  const showContent = !isHidden && !hasRailButNoSelection;

  return (
    <PaneContainer
      width={hasRailButNoSelection ? RAIL_WIDTH : width && isOnGrid ? width + 16 : width}
      isScrollable={isScrollable}
      responsive={responsive}
      style={style}
    >
      {responsive.desktop && isRailed && (
        <React.Fragment>
          <PaneRail railOptions={railOptions} isFullHeight={isFullHeight} />
          {showContent && <Line isVertical />}
        </React.Fragment>
      )}
      {showContent && (
        <PaneContent
          titleText={titleText}
          titleComponent={titleComponent}
          headerActionsComponent={headerActionsComponent}
          headerContentComponent={headerContentComponent}
          headerContainerStyle={headerContainerStyle}
          bodyContentComponent={bodyContentComponent}
          width={width}
          isOnGrid={isOnGrid}
          isScrollable={isScrollable}
          railOptions={railOptions}
        />
      )}
    </PaneContainer>
  );
};

export default Pane;
