// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

// App
import ConversationForm, {
  ConversationFormType,
  WrappedConversationFormType,
} from '@shared/modules/Communication/forms/ConversationForm';

const useUpdateConversationMutation = ({
  conversationForm,
  refetchQueries,
  onSuccess,
  onError,
}: {
  conversationForm: ConversationFormType;
  refetchQueries?: string[];
  onSuccess: () => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm<WrappedConversationFormType>({
    initialValues: {conversationForm},
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useUpdateConversationMutation.mutation,
    refetchQueries,
    variables: {
      conversationForm: ConversationForm.toMutation(form.values.conversationForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useUpdateConversationMutation.mutation = gql`
  mutation UpdateConversationMutation(
    $conversationForm: ConversationForm!,
  ) {
    response: updateConversation(
      conversationForm: $conversationForm,
    ) {
      ${gql.errors}
      conversation {
        id
      }
    }
  }
`;

export default useUpdateConversationMutation;
