import {EmailModel} from '@supermove/models';

import {parseJsonRecipients} from './parseJsonRecipients';

const forwardText = '---------- Forwarded Message ---------';

export const addForwardToBody = (email: EmailModel): string => {
  const toRecipients = parseJsonRecipients(email.toEmailRecipientsJson);
  const ccRecipients = parseJsonRecipients(email.ccEmailRecipientsJson);
  const bccRecipients = parseJsonRecipients(email.bccEmailRecipientsJson);
  return `${forwardText}<br>
  From: ${email.fromName} &lt;${email.fromEmail}&gt;<br>
  Date: ${email.sentAt}<br>
  Subject: ${email.subject}<br>
    ${toRecipients.length ? `To: ${toRecipients}<br>` : ''}
    ${ccRecipients.length ? `Cc: ${ccRecipients}<br>` : ''}
    ${bccRecipients.length ? `Bcc: ${bccRecipients}<br>` : ''}
  ---------- Original Message -----------
  <br><br>
  ${email.html}`;
};

export const addBodyToForwardedMessage = (body: string, forwardedBody: string): string => {
  return `${body}<br><br>${forwardedBody}`;
};
