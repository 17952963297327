// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Space, Styled} from '@supermove/components';
import {useResponsive, ResponsiveType} from '@supermove/hooks';
import {ViewStyleProp} from '@supermove/styles/types';

// Components
import Breadcrumbs, {BreadcrumbType} from '@shared/design/components/Breadcrumbs';
import LayoutBuilder from '@shared/design/components/Layout/components/LayoutBuilder';
import Tabs, {TabType, HandlePressTabType} from '@shared/design/components/Tabs';

const HeaderContainer = Styled.View`
`;

const HeaderContentContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 24 : 16)}px;
`;

const TitleRow = Styled.View`
  flex-direction: row;
  align-items: center;
`;

export type TabOptionsType<T> = {
  tabs: (TabType & T)[];
  activeTabIndex: number;
  handlePressTab: HandlePressTabType<T>;
};

const HeaderContent = ({
  breadcrumbs,
  titleText,
  titleComponent,
  headerActionsComponent,
  headerContentComponent,
}: {
  breadcrumbs?: BreadcrumbType[];
  titleText?: string;
  titleComponent?: React.ReactElement;
  headerActionsComponent?: React.ReactElement;
  headerContentComponent?: React.ReactElement;
}) => {
  const responsive = useResponsive();
  const hasBreadcrumbs = breadcrumbs && _.some(breadcrumbs);
  const hasTitle = !!titleText || !!titleComponent;

  return (
    <HeaderContentContainer responsive={responsive}>
      {hasBreadcrumbs && (
        <React.Fragment>
          <Space height={16} />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </React.Fragment>
      )}
      {hasTitle && (
        <React.Fragment>
          {hasBreadcrumbs ? <Space height={8} /> : <Space height={16} />}
          <TitleRow style={{flex: 1, width: '100%'}}>
            {!!titleText && (
              <React.Fragment>
                <LayoutBuilder.TitleText>{titleText}</LayoutBuilder.TitleText>
                <Space width={8} />
              </React.Fragment>
            )}
            {!!titleComponent && (
              <React.Fragment>
                {titleComponent}
                <Space width={8} />
              </React.Fragment>
            )}
            {!!headerActionsComponent && (
              <React.Fragment>
                <Space flex={1} />
                {headerActionsComponent}
              </React.Fragment>
            )}
          </TitleRow>
        </React.Fragment>
      )}
      {!!headerContentComponent && (
        <React.Fragment>
          {(hasBreadcrumbs || hasTitle) && <Space height={responsive.desktop ? 16 : 8} />}
          {headerContentComponent}
        </React.Fragment>
      )}
    </HeaderContentContainer>
  );
};

export interface PaneGroupHeaderType<T> {
  breadcrumbs?: BreadcrumbType[];
  tabOptions?: TabOptionsType<T>;
  titleText?: string;
  titleComponent?: React.ReactElement;
  headerActionsComponent?: React.ReactElement;
  headerContentComponent?: React.ReactElement;
  headerContainerStyle?: ViewStyleProp;
}

const PaneGroupHeader = <T,>({
  breadcrumbs,
  tabOptions,
  titleText,
  titleComponent,
  headerActionsComponent,
  headerContentComponent,
  headerContainerStyle,
}: PaneGroupHeaderType<T>) => {
  const responsive = useResponsive();
  return (
    <HeaderContainer style={headerContainerStyle}>
      <HeaderContent
        breadcrumbs={breadcrumbs}
        titleText={titleText}
        titleComponent={titleComponent}
        headerActionsComponent={headerActionsComponent}
        headerContentComponent={headerContentComponent}
      />
      <Space height={16} />
      {!!tabOptions && (
        <Tabs
          tabs={tabOptions.tabs}
          activeTabIndex={tabOptions.activeTabIndex}
          handlePressTab={tabOptions.handlePressTab}
          scrollViewStyle={{
            paddingLeft: responsive.desktop ? 24 : 16,
            paddingRight: responsive.desktop ? 24 : 16,
          }}
        />
      )}
    </HeaderContainer>
  );
};

export default PaneGroupHeader;
