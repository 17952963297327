// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';
import {ViewStyleProp} from '@supermove/styles/types';

// App
import BetaBadge from '@shared/design/components/BetaBadge';
import TertiaryButton from '@shared/design/components/Button/TertiaryButton';
import Text from '@shared/design/components/Text';

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Column = Styled.View`
`;

const Button = Styled.ButtonV2`
`;

export interface BreadcrumbType {
  name: string;
  onPress?: () => void;
  isBeta?: boolean;
}

type MobileOptions = {
  showPreviousScreenOnly?: boolean;
  showCurrentScreenOnly?: boolean;
  containerStyle?: ViewStyleProp;
};

type BreadcrumbsType = {
  breadcrumbs: BreadcrumbType[];
  containerStyle?: ViewStyleProp;
  mobileOptions?: MobileOptions;
};

const PreviousScreen = ({breadcrumb}: {breadcrumb: BreadcrumbType}) => {
  const {ref, isHovered} = useHover();
  return (
    <React.Fragment>
      <Button onPress={breadcrumb.onPress} ref={ref}>
        <Row>
          <Text.Heading2
            numberOfLines={1}
            style={{color: isHovered ? colors.blue.hover : colors.gray.secondary}}
          >
            {breadcrumb.name}
          </Text.Heading2>
          {breadcrumb.isBeta && (
            <React.Fragment>
              <Space width={8} />
              <BetaBadge />
            </React.Fragment>
          )}
        </Row>
      </Button>
      <Space width={12} />
      <Icon source={Icon.ChevronRight} color={colors.gray.secondary} size={14} />
      <Space width={12} />
    </React.Fragment>
  );
};

const CurrentScreen = ({breadcrumb}: {breadcrumb: BreadcrumbType}) => {
  return (
    <Row>
      <Text.Heading2 numberOfLines={1}>{breadcrumb.name}</Text.Heading2>
      {breadcrumb.isBeta && (
        <React.Fragment>
          <Space width={8} />
          <BetaBadge />
        </React.Fragment>
      )}
    </Row>
  );
};

const MobilePreviousScreen = ({
  previousPageBreadcrumb,
}: {
  previousPageBreadcrumb: BreadcrumbType;
}) => {
  return (
    <TertiaryButton
      onPress={previousPageBreadcrumb.onPress}
      iconLeft={Icon.AngleLeft}
      iconSize={16}
      text={previousPageBreadcrumb.name}
      style={{paddingVertical: 4}}
    />
  );
};

const DesktopBreadcrumbs = ({
  breadcrumbs,
  containerStyle = {flex: 1},
}: {
  breadcrumbs: BreadcrumbType[];
  containerStyle?: ViewStyleProp;
}) => {
  return (
    <Row style={containerStyle}>
      {breadcrumbs.map((breadcrumb: BreadcrumbType, index: number) => {
        // If last item, then we only need the name and no onPress for the current page
        if (index === breadcrumbs.length - 1) {
          return <CurrentScreen key={index} breadcrumb={breadcrumb} />;
        }
        return <PreviousScreen key={index} breadcrumb={breadcrumb} />;
      })}
    </Row>
  );
};

const MobileBreadcrumbs = ({
  breadcrumbs,
  mobileOptions = {},
}: {
  breadcrumbs: BreadcrumbType[];
  mobileOptions?: MobileOptions;
}) => {
  const previousScreenBreadcrumb =
    breadcrumbs.length > 1 ? breadcrumbs[breadcrumbs.length - 2] : undefined;
  const {showPreviousScreenOnly, showCurrentScreenOnly} = mobileOptions;

  return (
    <Column style={mobileOptions?.containerStyle}>
      {!showCurrentScreenOnly && previousScreenBreadcrumb && (
        <React.Fragment>
          <MobilePreviousScreen previousPageBreadcrumb={previousScreenBreadcrumb} />
          {!showPreviousScreenOnly && <Space height={8} />}
        </React.Fragment>
      )}
      {!showPreviousScreenOnly && (
        <CurrentScreen breadcrumb={breadcrumbs[breadcrumbs.length - 1]} />
      )}
    </Column>
  );
};

const Breadcrumbs = ({breadcrumbs, containerStyle, mobileOptions}: BreadcrumbsType) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      {responsive.desktop ? (
        <DesktopBreadcrumbs breadcrumbs={breadcrumbs} containerStyle={containerStyle} />
      ) : (
        <MobileBreadcrumbs breadcrumbs={breadcrumbs} mobileOptions={mobileOptions} />
      )}
    </React.Fragment>
  );
};

export default Breadcrumbs;
