import {AccountGrantKindModel} from '@supermove/models';

export interface CreateAccountGrantFormType {
  kind: AccountGrantKindModel;
  provider: string;
  redirectUri?: string;
  nylasCode?: string;
  userId?: string;
  organizationId: string;
}

const _new = ({
  kind,
  provider,
  redirectUri,
  nylasCode,
  userId,
  organizationId,
}: {
  kind: AccountGrantKindModel;
  provider: string;
  redirectUri?: string;
  nylasCode?: string;
  userId?: string;
  organizationId: string;
}): CreateAccountGrantFormType => {
  return {kind, provider, redirectUri, nylasCode, userId, organizationId};
};

const toMutation = (form: CreateAccountGrantFormType) => {
  return {
    kind: form.kind,
    provider: form.provider,
    redirectUri: form.redirectUri,
    nylasCode: form.nylasCode,
    userId: form.userId,
    organizationId: form.organizationId,
  };
};

const DeactivateAccountGrantForm = {
  new: _new,
  toMutation,
};

export default DeactivateAccountGrantForm;
