// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';

// App
import DeactivateAccountGrantForm, {
  DeactivateAccountGrantFormType,
} from '@shared/modules/AccountGrant/forms/DeactivateAccountGrantForm';

const useDeactivateAccountGrantMutation = ({
  deactivateAccountGrantForm,
  onSuccess,
  onError,
}: {
  deactivateAccountGrantForm: DeactivateAccountGrantFormType;
  onSuccess: () => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm<DeactivateAccountGrantFormType>({
    initialValues: deactivateAccountGrantForm,
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useDeactivateAccountGrantMutation.mutation,
    variables: {
      accountGrantForm: DeactivateAccountGrantForm.toMutation(form.values),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useDeactivateAccountGrantMutation.mutation = gql`
  mutation DeactivateAccountGrantMutation(
    $accountGrantForm: AccountGrantForm!,
  ) {
    response: deactivateAccountGrant(
      accountGrantForm: $accountGrantForm,
    ) {
      ${gql.errors}
      accountGrant {
        id
      }
    }
  }
`;

export default useDeactivateAccountGrantMutation;
