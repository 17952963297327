// Libraries
import React from 'react';

// Supermove
import {Icon, PreventPropagation} from '@supermove/components';
import {PopoverType, Form, useEffect, useHover, useResponsive} from '@supermove/hooks';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import IconButton from '@shared/design/components/IconButton';
import PopoverWithAction from '@shared/design/components/Popover/PopoverWithAction';

const useCommandEnterSubmit = ({handleSubmit}: {handleSubmit: () => void}) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'Enter') {
        event.preventDefault();
        handleSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSubmit]);
};

const EditingPopover = ({
  form,
  field,
  handleSubmit,
  isSubmitting,
  placeholder,
  popover,
  buttonLeftOffset,
  drawerHeaderText,
  children,
}: {
  form: Form;
  field: string;
  handleSubmit: () => void;
  isSubmitting: boolean;
  placeholder: string;
  popover: PopoverType;
  buttonLeftOffset: number;
  drawerHeaderText: string;
  children: React.ReactNode;
}) => {
  const responsive = useResponsive();
  useCommandEnterSubmit({handleSubmit});

  return (
    <PopoverWithAction
      popover={popover}
      drawerHeaderText={drawerHeaderText}
      handleSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      placement={'bottom-start'}
      offset={[-(4 + buttonLeftOffset), 6]}
      contentComponent={
        <FieldInput
          {...form}
          name={field}
          isResponsive
          input={{
            placeholder,
            autoFocus: true,
            multiline: true,
            style: {
              height: 160,
              width: responsive.desktop ? 344 : '100%',
              paddingTop: 8,
              paddingBottom: 8,
            },
          }}
        />
      }
    >
      {children}
    </PopoverWithAction>
  );
};

const EditFieldTextAreaButton = ({
  form,
  field,
  handleSubmit,
  isSubmitting,
  placeholder,
  drawerHeaderText,
  popover,
  buttonLeftOffset,
  isHidden,
  isDisabled,
  style,
}: {
  form: Form;
  field: string;
  handleSubmit: () => void;
  isSubmitting: boolean;
  placeholder: string;
  drawerHeaderText: string;
  popover: PopoverType;
  buttonLeftOffset: number;
  isHidden?: boolean;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}) => {
  const responsive = useResponsive();
  return (
    <PreventPropagation
      style={
        style ||
        (responsive.desktop
          ? {
              position: 'absolute',
              top: -3,
              left: buttonLeftOffset,
              zIndex: 1000,
            }
          : {
              paddingRight: 2,
            })
      }
    >
      <EditingPopover
        form={form}
        field={field}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        placeholder={placeholder}
        popover={popover}
        buttonLeftOffset={buttonLeftOffset}
        drawerHeaderText={drawerHeaderText}
      >
        <PreventPropagation>
          <IconButton
            source={Icon.Pen}
            onPress={popover.handleToggle}
            size={responsive.desktop ? 12 : 14}
            isDisabled={isDisabled}
            buttonStyle={{visibility: isHidden && !popover.isOpen ? 'hidden' : 'visible'}}
          />
        </PreventPropagation>
      </EditingPopover>
    </PreventPropagation>
  );
};

EditFieldTextAreaButton.useHover = () => {
  const {ref, isHovered} = useHover({hoverCheckType: 'enterLeave'});
  return {ref, isHovered};
};

export default EditFieldTextAreaButton;
