// Libraries
import _ from 'lodash';

// Supermove
import {gql} from '@supermove/graphql';
import {OrganizationModel, ProjectTypeModel} from '@supermove/models';
import {withFragment} from '@supermove/utils';

// App
import ProjectTypeUserFlowForm, {
  ProjectTypeUserFlowFormToFormType,
} from '@shared/modules/UserFlows/forms/ProjectTypeUserFlowForm';

export type ProjectTypeFormToFormType = {
  projectTypeId: string | undefined;
  organization: OrganizationModel;
  category: string;
  name: string;
  color: string;
  billingLibraryId: string | undefined;
  billTypeIds: string[];
  isLongDistance: boolean;
  isJobLevelBilling: boolean;
  projectTypeUserFlowForms: ProjectTypeUserFlowFormToFormType[];
};

const _new = withFragment(
  ({organization, category}: {organization: OrganizationModel; category: string}) => ({
    organizationId: organization.id,
    category,
    projectTypeId: null,
    name: '',
    color: '',
    billingLibraryId: null,
    billTypeIds: [],
    isLongDistance: false,
    isJobLevelBilling: false,
    projectTypeUserFlowForms: organization.userFlows
      .filter((userFlow) => userFlow.isDefault)
      .map((userFlow) =>
        ProjectTypeUserFlowForm.new({
          projectTypeId: undefined,
          userFlowId: _.toNumber(userFlow.id),
        }),
      ),
  }),
  gql`
    fragment ProjectTypeForm_new on Organization {
      userFlows {
        id
        isDefault
      }
    }
  `,
);

const edit = withFragment(
  (projectType: ProjectTypeModel) => ({
    projectTypeId: projectType.id,
    category: projectType.category,
    organizationId: projectType.organizationId,
    billingLibraryId: String(projectType.billingLibraryId),
    color: projectType.color,
    name: projectType.name,
    billTypeIds: projectType.activeBillTypeIds,
    isLongDistance: projectType.isLongDistance,
    isJobLevelBilling: projectType.isJobLevelBilling,
    projectTypeUserFlowForms: projectType.projectTypeUserFlows.map(ProjectTypeUserFlowForm.edit),
  }),
  gql`
    ${ProjectTypeUserFlowForm.edit.fragment}
    fragment ProjectTypeForm_edit on ProjectType {
      id
      category
      organizationId
      billingLibraryId
      color
      name
      activeBillTypeIds
      isLongDistance
      isJobLevelBilling
      projectTypeUserFlows {
        id
        ...ProjectTypeUserFlowForm_edit
      }
    }
  `,
);

const toForm = ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
  projectTypeUserFlowForms,
}: (ReturnType<typeof edit> | ReturnType<typeof _new>) & {index: number}) => ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
  projectTypeUserFlowForms,
});

const toMutation = ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
  projectTypeUserFlowForms,
}: ReturnType<typeof toForm>) => ({
  projectTypeId,
  index,
  organizationId,
  category,
  billingLibraryId,
  color,
  name,
  billTypeIds,
  isLongDistance,
  isJobLevelBilling,
  projectTypeUserFlowForms: projectTypeUserFlowForms.map(ProjectTypeUserFlowForm.toMutation),
});

const ProjectTypeForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default ProjectTypeForm;
