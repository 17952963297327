// Libraries
import _ from 'lodash';
import React from 'react';

// App
import {Icon, IconSource, ScrollView, Space, Styled} from '@supermove/components';
import {useHover, useResponsive} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// Components
import Line from '@shared/design/components/Line';
import TextTooltip from '@shared/design/components/TextTooltip';

export const RAIL_WIDTH = 56;

const RailContainer = Styled.View<{isFullHeight?: boolean}>`
  width: ${RAIL_WIDTH}px;
  padding-top: ${({isFullHeight}) => (isFullHeight ? 16 : 8)}px;
  background-color: ${colors.white};
`;

const RailActionButton = Styled.ButtonV2<{color: string}>`
  height: 40px;
  justifyContent: center;
  alignItems: center;
  background-color: ${({color}) => color};
`;

const RailActionIconContainer = Styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ToolbarContainer = Styled.View`
  flex-direction: row;
  height: 56px;
  justify-content: space-evenly;
  align-items: center;
  background-color: ${colors.white};
`;

const ToolbarActionButton = Styled.ButtonV2`
  height: 40px;
  width: ${RAIL_WIDTH}px;
  justifyContent: center;
  alignItems: center;
`;

export type RailAction = {
  icon: IconSource;
  handleAction: () => void;
  isSelected: boolean;
  isDisabled?: boolean;
  tooltip?: string;
};
export type RailOptions = {
  actions: RailAction[];
  isLoading?: boolean;
  handleClose?: () => void;
  mobileDrawer?: {
    isOpen: boolean;
    handleClose: () => void;
  };
};
export type WithRail = {
  railOptions: RailOptions;
};
export type WithoutRail = {
  railOptions?: never;
};

const RailAction = ({icon, handleAction, tooltip, isSelected, isDisabled = false}: RailAction) => {
  const {ref, isHovered} = useHover();
  const backgroundColor = isSelected || isHovered ? colors.blue.accent : 'transparent';
  const iconColor = !isSelected && isHovered ? colors.blue.hover : colors.blue.interactive;

  return (
    <React.Fragment>
      <Space height={8} />
      <TextTooltip text={tooltip} placement={'left'}>
        <RailActionButton
          ref={ref}
          onPress={handleAction}
          disabled={isDisabled}
          color={backgroundColor}
        >
          <Line
            isVertical
            weight={2}
            color={isSelected || isHovered ? iconColor : 'transparent'}
            style={{position: 'absolute', left: 0}}
          />
          <RailActionIconContainer>
            <Icon source={icon} color={iconColor} size={16} />
          </RailActionIconContainer>
        </RailActionButton>
      </TextTooltip>
      <Space height={8} />
    </React.Fragment>
  );
};

const ToolbarAction = ({icon, handleAction, isDisabled = false}: RailAction) => {
  return (
    <ToolbarActionButton onPress={handleAction} disabled={isDisabled}>
      <Icon source={icon} color={colors.blue.interactive} size={20} />
    </ToolbarActionButton>
  );
};

const PaneRail = ({
  railOptions,
  isFullHeight,
}: {
  railOptions: RailOptions;
  isFullHeight?: boolean;
}) => {
  const responsive = useResponsive();

  // Desktop vertical rail
  if (responsive.desktop) {
    return (
      <RailContainer isFullHeight={isFullHeight}>
        {railOptions.actions.map((action) => {
          return <RailAction key={_.toString(action.icon)} {...action} />;
        })}
      </RailContainer>
    );
  }

  // Mobile horizontal scrolling toolbar
  if (railOptions.actions.length > 5) {
    return (
      <ToolbarContainer>
        <ScrollView
          horizontal
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{alignItems: 'center'}}
        >
          <Space width={16} />
          {railOptions.actions.map((action, index) => {
            return (
              <React.Fragment key={_.toString(action.icon)}>
                {index > 0 && <Space width={16} />}
                <ToolbarAction {...action} />
              </React.Fragment>
            );
          })}
          <Space width={16} />
        </ScrollView>
      </ToolbarContainer>
    );
  }

  // Mobile horizontal fixed toolbar
  return (
    <ToolbarContainer>
      {railOptions.actions.map((action) => {
        return <ToolbarAction key={_.toString(action.icon)} {...action} />;
      })}
    </ToolbarContainer>
  );
};

export default PaneRail;
