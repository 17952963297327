const JOB = 'JOB';
const PROJECT = 'PROJECT';
const CALL = 'CALL';

const tagCategoryOptions = [
  {
    label: 'Project',
    value: PROJECT,
  },
  {
    label: 'Job',
    value: JOB,
  },
];

const TagCategory = {
  JOB,
  PROJECT,
  CALL,

  tagCategoryOptions,
};

export default TagCategory;
