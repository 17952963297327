// Libraries
import React from 'react';

// Supermove
import {Popover, Space, Styled, PopoverPlacementType} from '@supermove/components';
import {useResponsive, PopoverType} from '@supermove/hooks';
import {colors} from '@supermove/styles';

// App
import Button from '@shared/design/components/Button';
import QuaternaryButton from '@shared/design/components/Button/QuaternaryButton';
import DrawerWithAction from '@shared/design/components/Drawer/DrawerWithAction';
import PopoverBuilder from '@shared/design/components/Popover/components/PopoverBuilder';

const Row = Styled.View`
  flex-direction: row;
`;

const PopoverWithAction = ({
  // Popover props
  popover,
  placement,
  width = 360,
  offset = [0, 4],
  style,

  // Drawer props
  drawerHeaderText,

  // Shared props
  contentComponent,
  primaryActionText = 'Save',
  secondaryActionText = 'Cancel',
  handleSubmit,
  isSubmitting,
  isDisabled,
  children,
  isCloseableOutside = true,
}: {
  popover: PopoverType;
  placement?: PopoverPlacementType;
  width?: number;
  offset?: [number, number];
  style?: React.CSSProperties;
  drawerHeaderText: string;
  contentComponent: React.ReactNode;
  primaryActionText?: string;
  secondaryActionText?: string;
  handleSubmit: () => void;
  isSubmitting?: boolean;
  isDisabled?: boolean;
  children: React.ReactNode;
  isCloseableOutside?: boolean; // Not used in this component, but can be passed for consistency
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <PopoverBuilder.Content innerRef={popover.ref}>{children}</PopoverBuilder.Content>
      {responsive.desktop ? (
        <Popover
          reference={popover.ref}
          isOpen={popover.isOpen}
          handleOpen={popover.handleOpen}
          handleClose={popover.handleClose}
          placement={placement}
          offset={offset}
          isClosableOutside={isCloseableOutside}
        >
          <PopoverBuilder.PopoverContainer width={width} style={style}>
            {contentComponent}
            <Space height={12} />
            <Row>
              <QuaternaryButton
                text={secondaryActionText}
                textColor={colors.blue.interactive}
                onPress={popover.handleClose}
                style={{flex: 1}}
                isDisabled={isDisabled || isSubmitting}
                isWidthOfContainer
              />
              <Space width={8} />
              <Button
                text={primaryActionText}
                onPress={handleSubmit}
                style={{flex: 1}}
                isSubmitting={isSubmitting}
                isDisabled={isDisabled}
                isWidthOfContainer
              />
            </Row>
          </PopoverBuilder.PopoverContainer>
        </Popover>
      ) : (
        <DrawerWithAction
          key={popover.key}
          isOpen={popover.isOpen}
          headerText={drawerHeaderText}
          primaryActionText={primaryActionText}
          secondaryActionText={secondaryActionText}
          handleClose={popover.handleClose}
          handleSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          isDisabled={isDisabled}
        >
          {contentComponent}
        </DrawerWithAction>
      )}
    </React.Fragment>
  );
};

export default PopoverWithAction;
