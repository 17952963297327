// Libraries
import _ from 'lodash';

// Supermove
import {uuid} from '@supermove/utils';

export interface SendSmsV2FromTemplateActionFormType {
  workflowRunStepUuid: string;
  textMessageTemplateCategory: string | null;
  recipientKind: string | null;
  textMessageTemplateId: string | null;
  recipientUserIds: string[] | null;
  recipientTeamIds: string[] | null;
}

const _new = (): SendSmsV2FromTemplateActionFormType => ({
  workflowRunStepUuid: uuid(),
  textMessageTemplateCategory: null,
  recipientKind: null,
  textMessageTemplateId: null,
  recipientUserIds: [],
  recipientTeamIds: [],
});

const edit = (sendSmsV2FromTemplateActionForm: any): SendSmsV2FromTemplateActionFormType => ({
  workflowRunStepUuid: sendSmsV2FromTemplateActionForm.workflowRunStepUuid,
  textMessageTemplateCategory: sendSmsV2FromTemplateActionForm.textMessageTemplateCategory,
  recipientKind: sendSmsV2FromTemplateActionForm.recipientKind,
  textMessageTemplateId: sendSmsV2FromTemplateActionForm.textMessageTemplateId,
  recipientUserIds: sendSmsV2FromTemplateActionForm.recipientUserIds.map(
    (recipientUserId: string) => _.toNumber(recipientUserId),
  ),
  recipientTeamIds: sendSmsV2FromTemplateActionForm.recipientTeamIds,
});

const toForm = (
  SendSmsV2FromTemplateActionForm: SendSmsV2FromTemplateActionFormType,
): SendSmsV2FromTemplateActionFormType => ({
  ...SendSmsV2FromTemplateActionForm,
});

const toMutation = (
  SendSmsV2FromTemplateActionForm: SendSmsV2FromTemplateActionFormType,
): SendSmsV2FromTemplateActionFormType => ({
  ...SendSmsV2FromTemplateActionForm,
});

const SendSmsV2FromTemplateActionForm = {
  new: _new,
  edit,
  toForm,
  toMutation,
};

export default SendSmsV2FromTemplateActionForm;
