// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {ProjectModel} from '@supermove/models';

// App
import EmailForm, {EmailFormType} from '@shared/modules/Email/forms/EmailForm';

export interface WrappedEmailFormType {
  projectId?: string;
  emailForm: EmailFormType;
}

// This will be altered later, for now it's the same as the original
const useSendEmailForProjectMutation = ({
  emailForm,
  project,
  onSuccess,
  onError,
}: {
  emailForm: EmailFormType;
  project: ProjectModel;
  onSuccess: () => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm<WrappedEmailFormType>({
    initialValues: {
      projectId: project.id,
      emailForm,
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendEmailForProjectMutation.mutation,
    variables: {
      projectId: project.id,
      emailForm: EmailForm.toMutation(form.values.emailForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendEmailForProjectMutation.mutation = gql`
  mutation SendEmailForProjectMutation(
    $projectId: Int!,
    $emailForm: EmailForm!,
  ) {
    response: sendEmailForProject(
      projectId: $projectId,
      emailForm: $emailForm,
    ) {
      ${gql.errors}
      email {
        id
      }
    }
  }
`;

export default useSendEmailForProjectMutation;
