// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {FlatList, Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, Typography} from '@supermove/styles';
import {Currency} from '@supermove/utils';

// App
import FieldInput from '@shared/design/components/Field/FieldInput';
import CommercialMaterialForm from '@shared/modules/CommercialCatalog/forms/CommercialMaterialForm';

const Wrapper = Styled.View`
  margin-bottom: 10px;
  z-index: ${(props) => 100 - (props as any).index};
`;

const Header = Styled.View`
  align-self: stretch;
  flex-direction: row;
  padding-vertical: 5px;
  padding-horizontal: 10px;
  margin-bottom: 10px;
  background-color: ${colors.gray.background};
`;

const FlatListCell = Styled.View`
  z-index: ${(props) => 100 - (props as any).index};
`;

const FlatListSpace = Styled.View`
  height: 10px;
`;

const ItemRow = Styled.View`
  flex-direction: row;
  padding-horizontal: 10px;
`;

const NameCell = Styled.View`
  flex: 3;
  min-width: 140px;
  padding: ${({
    // @ts-expect-error TS(2339): Property 'isEditable' does not exist on type 'Them... Remove this comment to see the full error message
    isEditable,
  }) => (isEditable ? '2px' : '0px')}
`;

const ContentCell = Styled.View`
  flex: 1;
  ${({
    // @ts-expect-error TS(2339): Property 'isEditable' does not exist on type 'Them... Remove this comment to see the full error message
    isEditable,
  }) => (isEditable ? '' : 'align-items: flex-end')}
  padding: ${({
    // @ts-expect-error TS(2339): Property 'isEditable' does not exist on type 'Them... Remove this comment to see the full error message
    isEditable,
  }) => (isEditable ? '2px' : '0px')}
`;

const HeaderText = Styled.Text`
  ${Typography.Label3}
  color: ${colors.gray.secondary};
`;

const Name = Styled.H6`
  ${Typography.Body3};
`;

const ContentText = Styled.Text`
  ${Typography.Body3};
  color: ${colors.gray.secondary};
`;

const EmptyMessage = Styled.Text`
  ${Typography.Label2}
  color: ${colors.gray.secondary};
  padding-horizontal: 12px;
`;

const AddButton = Styled.ButtonV2`
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

const AddButtonText = Styled.Text`
  ${Typography.Label1}
  color: ${colors.blue.interactive};
`;

const RemoveRowButton = Styled.ButtonV2`
  width: 24px;
  justify-content: center;
  align-items: flex-end;
`;

const IconCircle = Styled.View`
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
  height: 20px;
  width: 20px;
  border-radius: 10px;
`;

const handleAddItem = ({form, field, job}: any) => {
  const commercialMaterialForm = CommercialMaterialForm.toForm(
    CommercialMaterialForm.new({commercialCatalogId: job.commercialCatalog.id, name: ''}),
  );
  const forms = _.get(form.values, field);
  form.setFieldValue(field, [...forms, commercialMaterialForm]);
};

const handleRemoveItem = ({form, field, index}: any) => {
  const commercialMaterialForms = _.get(form.values, field);
  const updatedForms = _.filter(commercialMaterialForms, (form, formIndex) => formIndex !== index);
  form.setFieldValue(field, updatedForms);
};

const ListHeader = ({isPriceVisible, isEditable}: any) => {
  return (
    <Header>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <NameCell isEditable={isEditable}>
        <HeaderText>Material</HeaderText>
      </NameCell>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ContentCell isEditable={isEditable}>
        <HeaderText>Quantity Requested</HeaderText>
      </ContentCell>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ContentCell isEditable={isEditable}>
        <HeaderText>Used</HeaderText>
      </ContentCell>
      {isPriceVisible && (
        // @ts-expect-error TS(2769): No overload matches this call.
        <ContentCell isEditable={isEditable}>
          <HeaderText>Price</HeaderText>
        </ContentCell>
      )}
      {isEditable && <RemoveRowButton disabled />}
    </Header>
  );
};

const ViewCommercialMaterialItem = ({commercialMaterial, isPriceVisible}: any) => {
  return (
    <ItemRow>
      <NameCell>
        <Name>{commercialMaterial.name}</Name>
      </NameCell>
      <ContentCell>
        <ContentText>{commercialMaterial.quantityRequested}</ContentText>
      </ContentCell>
      <ContentCell>
        <ContentText>{commercialMaterial.quantityDelivered}</ContentText>
      </ContentCell>
      {isPriceVisible && (
        <ContentCell>
          <ContentText>{Currency.display(commercialMaterial.price)}</ContentText>
        </ContentCell>
      )}
    </ItemRow>
  );
};

const EditCommercialMaterialItem = ({form, field, index}: any) => {
  return (
    <ItemRow>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <NameCell isEditable>
        <FieldInput.Memoized
          {...form}
          name={`${field}.${index}.name`}
          input={{
            placeholder: 'Enter material name',
            required: !_.get(form.values, `${field}.${index}.name`),
          }}
        />
      </NameCell>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ContentCell isEditable>
        <FieldInput.Memoized
          {...form}
          name={`${field}.${index}.quantityRequested`}
          input={{
            placeholder: 'Enter amount',
          }}
        />
      </ContentCell>
      {/* @ts-expect-error TS(2769): No overload matches this call. */}
      <ContentCell isEditable>
        <FieldInput.Memoized
          {...form}
          name={`${field}.${index}.quantityDelivered`}
          input={{
            placeholder: 'Enter amount',
          }}
        />
      </ContentCell>
      <RemoveRowButton onPress={() => handleRemoveItem({form, field, index})}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IconCircle color={colors.red.warning}>
          <Icon source={Icon.Minus} size={12} color={colors.red.warning} />
        </IconCircle>
      </RemoveRowButton>
    </ItemRow>
  );
};

const AddItemButton = ({job, form, field}: any) => {
  return (
    <ItemRow style={{marginTop: 10}}>
      <AddButton onPress={() => handleAddItem({job, form, field})}>
        {/* @ts-expect-error TS(2769): No overload matches this call. */}
        <IconCircle color={colors.blue.interactive}>
          <Icon source={Icon.Plus} size={12} color={colors.blue.interactive} />
        </IconCircle>
        <Space width={8} />
        <AddButtonText>Add custom material</AddButtonText>
      </AddButton>
    </ItemRow>
  );
};

const DocumentV2InputCommercialCatalogMaterialContentEdit = ({
  job,
  form,
  field,
  isPriceVisible,
}: any) => {
  const commercialMaterialFormsField = `${field}.commercialCatalogForm.commercialMaterialForms`;
  const commercialMaterialForms = _.get(form.values, commercialMaterialFormsField);
  return (
    <FlatList
      listKey={'EditCommercialMaterialsList'}
      data={commercialMaterialForms}
      keyExtractor={(commercialMaterialForm: any, index: any) => String(index)}
      renderItem={({index}: any) => {
        return (
          <EditCommercialMaterialItem
            form={form}
            field={commercialMaterialFormsField}
            index={index}
          />
        );
      }}
      CellRendererComponent={FlatListCell}
      ItemSeparatorComponent={FlatListSpace}
      ListHeaderComponent={<ListHeader isPriceVisible={isPriceVisible} isEditable />}
      ListEmptyComponent={<EmptyMessage>No Materials</EmptyMessage>}
      ListFooterComponent={
        <AddItemButton job={job} form={form} field={commercialMaterialFormsField} />
      }
    />
  );
};

const DocumentV2InputCommercialCatalogMaterialContentView = ({job, isPriceVisible}: any) => {
  return (
    <FlatList
      listKey={'ViewCommercialMaterialsList'}
      data={job.commercialCatalog.commercialMaterials}
      keyExtractor={(commercialMaterial, index) => String(index)}
      renderItem={({item: commercialMaterial}) => {
        return (
          <ViewCommercialMaterialItem
            commercialMaterial={commercialMaterial}
            isPriceVisible={isPriceVisible}
          />
        );
      }}
      CellRendererComponent={FlatListCell}
      ItemSeparatorComponent={FlatListSpace}
      ListHeaderComponent={<ListHeader isPriceVisible={isPriceVisible} />}
      ListEmptyComponent={<EmptyMessage>No Materials</EmptyMessage>}
    />
  );
};

const DocumentV2InputCommercialCatalogMaterial = ({
  index,
  isPreview,
  isEditable,
  job,
  form,
  field,
}: any) => {
  const isPriceEnabled =
    isPreview || job.organization.features.isEnabledShowEquipmentAndMaterialsPriceOnDocuments;
  const isPriceVisible = isPriceEnabled && !isEditable;
  return (
    // @ts-expect-error TS(2769): No overload matches this call.
    <Wrapper index={index}>
      {isPreview || !isEditable ? (
        <DocumentV2InputCommercialCatalogMaterialContentView
          job={isPreview ? MOCK_JOB_DATA : job}
          isPriceVisible={isPriceVisible}
        />
      ) : (
        <DocumentV2InputCommercialCatalogMaterialContentEdit
          job={job}
          form={form}
          field={field}
          isPriceVisible={isPriceVisible}
        />
      )}
    </Wrapper>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
DocumentV2InputCommercialCatalogMaterial.fragment = gql`
  fragment DocumentV2InputCommercialCatalogMaterial on Job {
    id
    organization {
      id
      commercialMaterialTypes {
        id
        name
        price
      }
      features {
        isEnabledShowEquipmentAndMaterialsPriceOnDocuments: isEnabled(
          feature: "showEquipmentAndMaterialsPriceOnDocuments"
        )
      }
    }
    commercialCatalog {
      id
      commercialMaterials {
        id
        name
        quantityDelivered
        quantityRequested
        price
      }
    }
  }
`;

// --------------------------------------------------
// Mock-Data for isPreview=true
// --------------------------------------------------
const MOCK_JOB_DATA = {
  id: 1,
  organization: {
    id: 1,
    features: {
      isEnabledShowEquipmentAndMaterialsPriceOnDocuments: true,
    },
  },
  commercialCatalog: {
    id: 1,
    commercialMaterials: [
      {
        id: 1,
        name: 'Material 1',
        quantityDelivered: 1,
        quantityRequested: 2,
        price: 1000,
      },
    ],
  },
};

export default DocumentV2InputCommercialCatalogMaterial;
