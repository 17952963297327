// Supermove
import {gql} from '@supermove/graphql';
import {MutationError, useForm, useFormMutation} from '@supermove/hooks';
import {EmailTemplateModel, ProjectModel} from '@supermove/models';

// App
import EmailForm, {EmailFormType} from '@shared/modules/Email/forms/EmailForm';

export interface WrappedEmailFormType {
  projectId?: string;
  emailForm: EmailFormType;
}

const useSendEmailForProjectMutationV1 = ({
  viewerId,
  project,
  emailTemplate,
  threadId,
  subject,
  onSuccess,
  onError,
}: {
  viewerId: string;
  project: ProjectModel;
  emailTemplate?: EmailTemplateModel;
  threadId?: string;
  subject?: string;
  onSuccess: () => void;
  onError: (errors: MutationError[]) => void;
}) => {
  const form = useForm<WrappedEmailFormType>({
    initialValues: {
      projectId: project.id,
      emailForm: emailTemplate
        ? EmailForm.newFromProjectWithEmailTemplate({
            viewerId,
            project,
            emailTemplate,
            threadId,
            subject,
            kind: emailTemplate.kind,
          })
        : EmailForm.newFromProject({project, viewerId, threadId, subject}),
    },
  });

  const {submitting, handleSubmit} = useFormMutation({
    form,
    mutation: useSendEmailForProjectMutationV1.mutation,
    variables: {
      projectId: project.id,
      emailForm: EmailForm.toMutation(form.values.emailForm),
    },
    onSuccess,
    onError,
  });

  return {
    form,
    submitting,
    handleSubmit,
  };
};

// --------------------------------------------------
// Data
// --------------------------------------------------
useSendEmailForProjectMutationV1.fragment = gql`
  ${EmailForm.newFromProject.fragment}
  ${EmailForm.newFromProjectWithEmailTemplate.fragment}

  fragment useSendEmailForProjectMutationV1_Project on Project {
    id
    ...EmailForm_newFromProject
    ...EmailForm_newFromProjectWithEmailTemplate_Project
  }

  fragment useSendEmailForProjectMutationV1_EmailTemplate on EmailTemplate {
    id
    ...EmailForm_newFromProjectWithEmailTemplate_EmailTemplate
  }
`;

useSendEmailForProjectMutationV1.mutation = gql`
  mutation SendEmailForProjectMutationV1(
    $projectId: Int!,
    $emailForm: EmailForm!,
  ) {
    response: sendEmailForProject(
      projectId: $projectId,
      emailForm: $emailForm,
    ) {
      ${gql.errors}
      email {
        id
      }
    }
  }
`;

export default useSendEmailForProjectMutationV1;
