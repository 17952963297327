const AM_ONLY = '1200';
const PM_ONLY = '2400';

const CAPACITY_CALENDAR_TIME_FILTER_OPTIONS = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'AM only',
    value: AM_ONLY,
    description: 'Jobs starting before 12:00 PM',
  },
  {
    label: 'PM only',
    value: PM_ONLY,
    description: 'Jobs starting after 12:00 PM',
  },
];

const getCapacityCalendarTimeFilterDisplay = (timeFilter: string | null) => {
  switch (timeFilter) {
    case '1200':
      return 'AM only';
    case '2400':
      return 'PM only';
    default:
      return 'All';
  }
};

const getCapacityCalendarTimeFilterKindDisplay = (timeFilter: string | null) => {
  switch (timeFilter) {
    case '1200':
      return 'AM ';
    case '2400':
      return 'PM ';
    default:
      return '';
  }
};

const CapacityCalendarTimeFilter = {
  AM_ONLY,
  PM_ONLY,

  CAPACITY_CALENDAR_TIME_FILTER_OPTIONS,

  getCapacityCalendarTimeFilterDisplay,
  getCapacityCalendarTimeFilterKindDisplay,
};

export default CapacityCalendarTimeFilter;
